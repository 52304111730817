import React, {Component} from "react";

import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import {
  AdicionarDocumentoWrapper,
  // ToogleSerasaWrapper,
  // ToogleSerasaActionWrapper,
} from "./AdicionarDocumentoModal.styled";

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";
import LeadHanlder from "../../../services/leadsHandler";
import FormCpfComponent from "./forms/cpf/Cpf";
import FormCnpjComponent from "./forms/cnpj/Cnpj";
import FormCcmComponent from "./forms/ccm/Ccm";
import Validators from "../../../services/Validators";

// import ToggleButton from 'react-toggle-button'


/**
 * Modal para adicionar um novo documento.
 *
 * @class adicionarDocumentoModal
 * @extends {Component}
 */
class AdicionarDocumento extends Component {

  constructor(props) {
    super(props);

    this.state = {
      novoDocumentoInput: "",
      valueRg: "",
      erroExiste: false,
      cpfIsValid: true,
      cnpjIsValid: true,
      ccmIsValid: true,
      enabledSerasa: false,
    };

  }

  // Fecha o modal.
  fechaModal = () => {
    if(this.props.leadsReducer.get("adicionarDocumentoSuccess"))
      this.props.adicionarFalseSuccessDocumento();
    this.setState({novoDocumentoInput: "", valueRg: "", erroExiste: false, cpfIsValid: true,
      enabledSerasa: false,
      cnpjIsValid: true, ccmIsValid: true}, () => {
      this.props.fechaModal();
    })
  }

  // Entrada de dados do novo documento.
  novoDocumentoInputHandler = (valor) => {
    const tipo = this.props.tipo

    // Adiciona máscaras
    if(tipo === "cpf") {
      const cpf = Validators.mascaraCpf(valor);
      this.setState({novoDocumentoInput: cpf, erroExiste: false, cpfIsValid: true});
    }

    else if(tipo === "cnpj") {
      const cnpj = Validators.mascaraCnpj(valor);
      this.setState({novoDocumentoInput: cnpj, erroExiste: false, cnpjIsValid: true});
    }

    else {
      const ccm = Validators.mascaraCcm(valor);
      this.setState({novoDocumentoInput: ccm, erroExiste: false, ccmIsValid: true});
    }
  }

  // Entrada de dados do rg.
  novoRgInputHandler = (valor) => {
    this.setState({valueRg: valor, erroExiste: false});
  }

  // Adiciona documento.
  adicionarDocumento = () => {
    const leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    const tipo = this.props.tipo;
    let novoDocumento = this.state.novoDocumentoInput;
    novoDocumento = Validators.removeMascara(novoDocumento);

    if(tipo === "cpf") {
      if(this.state.valueRg.length === 0)
        return;
      const novoRg = this.state.valueRg;
      const cpfIsValid = Validators.validaCPF(novoDocumento)
      if(cpfIsValid) {
        novoDocumento = {
          cpf: novoDocumento,
          rg: novoRg
        }
      }
      else {
        this.setState({cpfIsValid: false});
        return true;
      }
    }
    else if(tipo === "cnpj") {
      const cnpjIsValid = Validators.validaCnpj(novoDocumento);
      if(cnpjIsValid) {
        novoDocumento = {
          cnpj: novoDocumento
        }
      }
      else {
        this.setState({cnpjIsValid: false})
        return true;
      }
    }

    else {
      const ccmIsValid = Validators.validaRegistroImobiliario(novoDocumento);
      if(ccmIsValid) {
        novoDocumento = {
          ccm: novoDocumento
        }
      }
      else {
        this.setState({ccmIsValid: false})
        return true;
      }
    }

    const existe = LeadHanlder.selecionaDocumento(leadSelecionado, tipo,  novoDocumento) === -1 ? false : true;
    if(existe) {
      this.setState({erroExiste: true});
    }
    else {
      const serasaControl = this.state.enabledSerasa;
      this.props.adicionarDocumento(leadSelecionado.lead_id, tipo, novoDocumento, serasaControl);
    }
  }

  componentDidUpdate() {
    if(this.props.leadsReducer.get("adicionarDocumentoSuccess")) {
      this.fechaModal();
    }
  }

  // Renderiza o formulário de acordo com o tipo dele.
  documentoTipoRender = (tipo) => {
    if(tipo === "cpf")
      return <FormCpfComponent value={this.state.novoDocumentoInput} valueRg={this.state.valueRg}
       entrada={this.novoDocumentoInputHandler} entradaRg={this.novoRgInputHandler}
       validate={this.state.cpfIsValid} />
    else if(tipo === "cnpj")
      return <FormCnpjComponent value={this.state.novoDocumentoInput}
      entrada={this.novoDocumentoInputHandler} validate={this.state.cnpjIsValid} />
    else
      return <FormCcmComponent value={this.state.novoDocumentoInput}
      entrada={this.novoDocumentoInputHandler} validate={this.state.ccmIsValid}/>
  }

  // Serasa checked
  handlerCheckedChange = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        enabledSerasa: !prevState.enabledSerasa,
      }
    })
  }

  render() {
    const adicionarDocumentoFail = this.props.leadsReducer.get("adicionarDocumentoFail");
    const adicionarDocumentoLoad = this.props.leadsReducer.get("adicionarDocumentoLoad");
    const tipo = this.props.tipo.toUpperCase();
    const tipoForm = this.documentoTipoRender(this.props.tipo);
    return(
      <Modal size="mini" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon='file text' content={`Adicionar ${tipo}`} />
        <Modal.Content>
          <AdicionarDocumentoWrapper erroExiste={this.state.erroExiste} erroServidor={adicionarDocumentoFail} >
            {
              tipoForm
            }
            <p className="erro-existe">Documento já existente</p>
            <p className="erro-servidor">Não foi possível adicionar</p>
          </AdicionarDocumentoWrapper>

          {/*{
            this.props.tipo === "cpf" || this.props.tipo === "cnpj"
            ?
            <ToogleSerasaWrapper>
              <ToogleSerasaActionWrapper>
              <ToggleButton
                value={this.state.enabledSerasa}
                onToggle={this.handlerCheckedChange}
                inactiveLabel={<span>Não</span>}
                activeLabel={<span>Sim</span>}
                colors={{
                  active: {
                    base: '#c9204b',
                    hover: '#c9204b',
                  },
                }}
              />
                <span>Certidão do Serasa Concentre</span>
              </ToogleSerasaActionWrapper>
            </ToogleSerasaWrapper>
            :
            null
          }*/}

        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.adicionarDocumento} loading={adicionarDocumentoLoad}
          disabled={adicionarDocumentoLoad}>
            <Icon name='checkmark' /> Adicionar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adicionarDocumento: (lead, tipo, documento, serasa) => {
      dispatch(leadsActions.adicionarDocumento(lead, tipo, documento, serasa))
    },
    adicionarFalseSuccessDocumento: () => {
      dispatch(leadsActions.adicionarFalseSuccessDocumento())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarDocumento);
