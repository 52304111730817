import React, {Component} from "react";

import { INOVA_MIND_LOGO } from "./../../components/UI/Assets";
import { Input, Button } from 'semantic-ui-react';
import { LoginWrapper, EntradaDadosWrapper, LogoImageWrapper } from "./Auth.styled";

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as authActions from "./../../redux/auth/auth.actions";
// import BotaoInovaMind from "../../components/UI/BotaoInovaMind";


// Tela que vai gerenciar o login do sistema.
class AuthScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      usuarioValor: "",
      senhaValor: "",
      load: false,
    }
    this.erroValidacao = false;
  }

  // Entra com os dados dos inputs.
  onChangeTextHandler = (e) => {
    this.erroValidacao = false;
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    this.setState({[name]: value});
  }

  // Envia o formulário
  enviaFormulário = (event) => {
    if(this.state.usuarioValor.length !== 0 && this.state.senhaValor.length !== 0) {
      this.setState({load: true});
      this.props.validaUsuario(this.state.usuarioValor, this.state.senhaValor);
    }
    event.preventDefault();
  }

  // Muda a rota para a página home
  navegaParaHome = () => {
    const usuario = this.props.authReducer.get("usuario");
    if(usuario !== null) {
      this.props.history.push({
        pathname: "/home",
      });
    }
  }

  componentDidMount() {
    if(this.props.authReducer.get("token") !== null) {
      this.navegaParaHome();
    }
  }

  // Executa toda vez que alguma coisa é atualizada.
  componentDidUpdate() {
    if(this.props.authReducer.get("token") !== null) {
      this.navegaParaHome();
    }
    this.erroValidacao = false;
  }

  render() {
    const erroValidacao = this.props.authReducer.get("fail");
    const load = this.props.authReducer.get("load");
    const userInput = this.state.usuarioValor.length;
    const passInput = this.state.senhaValor.length;

    return(
      <LoginWrapper className="login-wrapper">
        <EntradaDadosWrapper className="entrada-dados" 
        onSubmit={this.enviaFormulário}>
        <LogoImageWrapper className="logo-wrapper">
          <img src={INOVA_MIND_LOGO} alt="loft" width="50px" height="50px"/>
        </LogoImageWrapper>
        <div className="entrada-acoes">
        <Input icon='users' iconPosition='left' placeholder='Usuário' 
            name="usuarioValor" value={this.state.usuarioValor} 
            onChange={(event) => this.onChangeTextHandler(event)} />
          <Input icon='lock' iconPosition='left' placeholder='Senha' type="password"
            name="senhaValor" value={this.state.senhaValor}
            onChange={(event) => this.onChangeTextHandler(event)} />
            <input type="submit" value="Submit" hidden />
          <div className="botao-entrar">
            <Button disabled={userInput === 0 || passInput === 0 || load ? true : false} 
              loading={load} onClick={this.enviaFormulário} fluid >Entrar</Button>
          </div>
          {
            erroValidacao ?
            <span style={{color: "red", marginTop: "0.6rem", textAlign: "center"}} >Login inválido</span>
            :
            null
          }
        </div>
        </EntradaDadosWrapper>
      </LoginWrapper>

    );
  }
}

// export default withRouter(LoginComponent);

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validaUsuario: (usuario, senha) => {
      dispatch(authActions.validaUsuario(usuario, senha))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthScreen));
