import styled from "styled-components";

export const AdicionarDocumentoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .entrada-cpf {
    width: 90%;

    & > div {
      margin-bottom: 0.5rem;
    }
  }

  & .erro-existe {
    margin-top: 0.3rem;
    color: red;
    display: ${props => props.erroExiste ? "block" : "none"};
  }

  & .erro-servidor {
    margin-top: 0.3rem;
    color: red;
    display: ${props => props.erroServidor ? "block" : "none"};
  }
`;

export const ToogleSerasaWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.6rem;
  margin-left: 1.2rem;
`;

export const ToogleSerasaActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    margin-left: 0.6rem;
  };

`;
