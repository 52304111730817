import React, {Component} from "react";

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";

import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

import axios from "axios";

import { reqS3PresignerUrl } from '../../../services/apiFilesDownloader';



/**
 * Modal para gerar zip dos documentos.
 *
 * @class GerarZipModal
 * @extends {Component}
 */
class GerarZipModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      load: false,
      erro: false,
    }

    this.listaVazia = false;
  }

  // Fecha o modal.
  fechaModal = () => {
    this.listaVazia = false;
    this.setState({erro: false, load: false}, () => {
      this.props.fechaModal();
    });
  }


  // Gera o zip.
  gerarZip = () => {
    this.listaVazia = false;
    this.setState({load: true, erro: false}, () => {
      let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
      // console.log(leadSelecionado);
      const listaZip = this.geraListaDocumentosZip(leadSelecionado);

      if(listaZip.length === 0) {
        console.log("VAZIA");
        this.listaVazia = true;
        this.setState({load: false});
      }
      else {
        this.saveZip(leadSelecionado, listaZip);
      }
      // console.log(listaZip);
    })
  }

  geraListaDocumentosZip = (lead) => {
    const listaCpf = [...lead.cpf.lista];
    const listaCnpj = [...lead.cnpj.lista];
    const listaCcm = [...lead.ccm.lista];

    const listazip = [];
    const cpfListaProcessed = this.getDocsProcessed(listaCpf, "cpf");
    const cnpjListaProcessed = this.getDocsProcessed(listaCnpj, "cnpj");
    const ccmListaProcessed = this.getDocsProcessed(listaCcm, "ccm");

    listazip.push(...cpfListaProcessed);
    listazip.push(...cnpjListaProcessed);
    listazip.push(...ccmListaProcessed);

    return [...listazip];
  }

  getDocsProcessed = (lista, tipo) => {
    const listazip = [];
    lista.forEach(item => {
      for (let elemento in item) {
        if(elemento.startsWith("data")) {
          if(item[elemento].processed && item[elemento].document_url !== null &&
            item[elemento].document_url.startsWith("http")) {
            listazip.push({
              tipo: tipo,
              numero: item.numero,
              name: item[elemento].title,
              url: item[elemento].document_url
            })
          }
        }

      }
    });
    return [...listazip];
  }

 urlToPromise = async (url) => {

  const config = {
    'responseType': 'arraybuffer',
    headers: {
      'Cache-Control' : 'no-cache'
    },
  };
  let presignerUrl = '';
  try {
    const response = await reqS3PresignerUrl(url);

    presignerUrl = response.data.url;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else if (err.request) {
      console.log(err.request);
    } else {
      console.log(err.message);
    }
    presignerUrl = '';
  }

  return new Promise((resolve, reject) => {
    axios.get(presignerUrl, config).then(res => {
      // console.log(res);
      resolve(res.data);
    }).catch(err => {
      // console.log(err);
      reject(err);
    })
  })

  /* return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
          if(err) {
            // console.log(err);
              reject(err);
          } else {
              console.log(data);
              resolve(data);
          }
      });
    }); */
  }

  saveZip = (leadSelecionado, listaUrl) => {
    const nameLead = leadSelecionado.name
    let zip = new JSZip();
    zip.folder(nameLead); // cria um diretorio com o nome do lead
    zip.folder(`${nameLead}/cpf`); // cria um diretorio com o nome do lead e cpf
    zip.folder(`${nameLead}/cnpj`); // cria um diretorio com o nome do lead e cnpj
    zip.folder(`${nameLead}/ccm`); // cria um diretorio com o nome do lead e ccm

    // Cria as pastas.
    listaUrl.forEach(item => {
      zip.folder(`${nameLead}/${item.tipo}/${item.numero}`);
    })


    listaUrl.forEach(item => {
      let pdf_name = `${item.numero} - ${item.name}`;
      zip.file(`${nameLead}/${item.tipo}/${item.numero}/${pdf_name}.pdf`, this.urlToPromise(item.url), {binary:true});
    });
    zip.generateAsync({type:"blob"})
    .then((content) => {
        const zip_name = `${nameLead}.zip`;
        saveAs(content, zip_name);
        this.listaVazia = false;
        this.setState({load: false}, () => {
          this.fechaModal();
        });
    }).catch(() => {
      this.listaVazia = false;
      this.setState({erro: true, load: false});
    })
  }


  componentWillUnmount() {
    this.fechaModal();
  }


  render() {
    // console.log(this.state.load);
    let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    const lead = leadSelecionado === null ? "" : leadSelecionado.name;
    return(
      <Modal size="tiny" open={this.props.showModalGerarZip} closeIcon onClose={this.fechaModal} >
        <Header icon='download' content={`Baixar Documentos`} />
        <Modal.Content>
          <p>
            <span>Baixar Documentos do lead: </span>
            <strong>{lead}</strong>
          </p>
          <p style={{display: this.listaVazia ? "block" : "none", color: "red", textAlign: "center"}}>
            Sem documentos para baixar
          </p>
          <p style={{display: this.state.erro ? "block" : "none", color: "red", textAlign: "center"}}>
            Erro! Tente novamente
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.fechaModal} disabled={this.state.load}>
            <Icon name='remove' /> Não
          </Button>
          <Button color='green' onClick={this.gerarZip} disabled={this.state.load} loading={this.state.load}>
            <Icon name='checkmark' /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deletarDocumento: (lead, tipo, documento) => {
      dispatch(leadsActions.deletarDocumento(lead, tipo, documento))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GerarZipModal);
