import React from "react";

import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";
import { INOVA_MIND_COLOR } from "../../UI/Assets";

const ListarItensLeadsWrapper = styled.div`
  flex: 1;

  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  /* padding-right: 0.4rem; */

  /* height: 400px; */

  & ul {
    margin-top: 1.5rem;
    padding-inline-start: 0.5rem;
  }

  & > ul > li {
    margin: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }


  & .lead-li-nome {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: black;
  }

  & .lead-item-nome {
    width: 100%;
  }

  & > ul > li:hover {
    & .lead-li-nome {
      color: ${INOVA_MIND_COLOR} !important;
    }
  }

  & .icones-lista-leads {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .icone-arquivar-lead {
    background-color: gold;
    border: 1px solid transparent;
    border-radius: 3rem;
  }

`;

/**
 * Lista os Leads passados na props.
 *
 */
const ListarItensLeadsComponent = (props) => {
  // console.log(props.leads);
  return (
    <ListarItensLeadsWrapper className="listar-itens-leads">
      <ul>
        {props.leads.map(lead => {

          const name = lead.name;
          let shortName = "";
          let isBigger = false;

          if(name.length > 20) {
            isBigger = true;
            shortName = name.slice(0, 20);
            shortName = shortName + "...";
          }

          return(
            <li key={lead.lead_id} style={{listStyleType: "none"}}>
              <div className="lead-item-nome" onClick={() => props.selecionaNovoLead(lead.lead_id)}>
                <Icon name="dot circle" size="small" color={props.selecionado === lead.name && props.selecionado !== null ? "green" : "black"} />
                {
                  isBigger
                  ?
                  <Popup
                    trigger={
                      <span className="lead-li-nome"
                        style={{color: props.selecionado === lead.name && props.selecionado !== null ? INOVA_MIND_COLOR : "black"}} >
                        {shortName}
                      </span>
                    }
                    content={name}
                    position='top center'
                    inverted
                  />
                  :
                  <span className="lead-li-nome"
                    style={{color: props.selecionado === lead.name && props.selecionado !== null ? INOVA_MIND_COLOR : "black"}} >
                    {name}
                  </span>
                }
              </div>
              <div className="icones-lista-leads">
                {
                  props.category === "Arquivado" && lead.archived
                  ?
                  null
                  :
                  <div>
                    <Icon name={lead.processed ? "check circle outline" : "wait"}
                      color={lead.processed ? "green" : "orange"} />
                  </div>
                }

                <Popup
                  trigger={
                    <div onClick={() => props.arquivarLead(lead)} style={{cursor: "pointer", marginLeft: "0.2rem"}}>
                      <Icon style={{backgroundColor: "#f1f1f1"}}
                        name={!lead.archived ? "folder" : "folder open"}
                        color={lead.archived ? "brown" : "black"}
                        size="small"
                        bordered circular/>
                    </div>
                  }
                  content={lead.archived ? "Desarquivar Lead" : "Arquivar Lead"}
                  position='top center'
                  inverted
                />
              </div>
            </li>
          )
        })}
      </ul>
    </ListarItensLeadsWrapper>
  );
}

export default ListarItensLeadsComponent;
