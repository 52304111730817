import React, {Component} from "react";
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

/**
 * Modal para deletar um lead.
 *
 * @class ModalConfirm
 * @extends {Component}
 */
class ModalConfirm extends Component {

  render() {
    const { show, onCancel, onConfirm, iconId, title, description } = this.props

    return(
      <Modal size="mini" open={show} closeIcon onClose={onCancel} >
        <Header icon={iconId} content={title} size={'tiny'} />
        <Modal.Content>
          <p>{description}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={onCancel}>
            <Icon name='remove' /> Não
          </Button>
          <Button color='green' onClick={onConfirm}>
            <Icon name='checkmark' /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ModalConfirm
