import React, {Component} from "react";
import { HomeWrapper, LeadsHomeWrapper, LeadsLoadWrapper, LeadsFailWrapper } from "./Home.styled";
import NavbarComponent from "./navbar/Navbar";

import ListarLeadsComponent from "./listarLeads/ListarLeads";
import LeadsComponent from "./leads/Leads";

import { connect } from 'react-redux';
import * as authActions from "./../../redux/auth/auth.actions";
import * as leadsActions from "./../../redux/leads/leads.actions";
import { withRouter } from "react-router-dom";

import { Icon, Button } from "semantic-ui-react";


/**
 * Componente principal da aplicação, renderiza a barra de navegação e os demais.
 *
 * @class HomeComponent
 * @extends {Component}
 */
class HomeComponent extends Component {

  /* constructor(props) {
    super(props);
  } */

  componentWillMount() {
    this.props.requestLeads();
  }

  navegaParaLogin = () => {
    // seta null em usuario no reducer.
    this.props.removeUsuario();
    this.props.zeraReducer();
    this.props.history.push({
      pathname: "/login",
    });
  }

  componentDidUpdate() {
    let leadsFail = this.props.leadsReducer.get("fail");
    // console.log(leadsFail);
    if(leadsFail) {
      this.navegaParaLogin();
    }
  }

  // Reseta a página atual
  resetPagina = () => {
    // console.log("RESETA PÁGINA");
    window.location.reload();
  }

  // Faz o ajuste de renderização de acordo com o lead.
  getLeadsRender = () => {
    const leads = this.props.leadsReducer.get("leads");
    const failLeads = this.props.leadsReducer.get("fail");
    // leads === null
    if(leads === null) {
      return(
        <LeadsLoadWrapper className="leads-load">
           <span>Carregando os Leads</span>
           <Icon name="spinner" size="huge" loading />
        </LeadsLoadWrapper>
      );
    }

    else if(failLeads) {
      return(
        <LeadsFailWrapper>
          <span>Erro ao carregar os leads</span>
          <Button size="big" primary onClick={this.resetPagina}>Tentar novamente</Button>
        </LeadsFailWrapper>
      );
    }

    else {
      return(
        <HomeWrapper className="home-wrapper">
          <NavbarComponent navegaParaLogin={this.navegaParaLogin}
          user={this.props.authReducer.get("usuario")} />
          <LeadsHomeWrapper className="leads-home-wrapper">
            <ListarLeadsComponent />
            <LeadsComponent />
          </LeadsHomeWrapper>
         </HomeWrapper>
      );
    }

  }
  
  render() {
    // const loadLeads = this.props.leadsReducer.get("leads");
    // console.log(loadLeads);
    const getLeadsRender = this.getLeadsRender();
    return getLeadsRender;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeUsuario: () => {
      dispatch(authActions.removeUsuario())
    },
    requestLeads: () => {
      dispatch(leadsActions.requestLeads())
    },
    zeraReducer: () => {
      dispatch(leadsActions.zeraReducer())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeComponent));