import React, {Component} from "react";

import HomeComponent from "../../components/home/Home";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

// Tela principal da aplicação.
class HomeScreen extends Component {

  // Verifica se o usuário está autenticado, se não tiver volta para a tela de login.
  componentWillMount() {
    /* const usuario = this.props.authReducer.get("usuario");
    if(usuario === null) {
      this.props.history.push({
        pathname: "/login",
      });
    } */

    // Descomente acima para ativar a guarda de rota.

  }

  render() {
    return(
      <HomeComponent />
    );
  } 
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  }
}

export default connect(mapStateToProps, null)(withRouter(HomeScreen));
