import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
// import logger from 'redux-logger';
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import immutableTransform from 'redux-persist-transform-immutable'

import reducersRoots from "./root.reducers";
import epicsRoots from "./root.epics";
import * as crypt from 'crypto-js';

const SetTransform = createTransform(
  
  // Criptografa o persist.
  (inboundState, key) => {
    return crypt.AES.encrypt(inboundState, "inova#2018").toString();
  },
  
  // Descriptografa.
  (outboundState, key) => {
    const bytes = crypt.AES.decrypt(outboundState, "inova#2018");
    const data = JSON.parse(bytes.toString(crypt.enc.Utf8));
    return JSON.stringify(data);
  },
  
  // define which reducers this transform gets called for.
  { whitelist: ['authReducer'] }
);

const persistConfig = {
  transforms: [immutableTransform(), SetTransform],
  key: "auth",
  storage,
  blacklist: ["leadsReducer"],
}

const persistedReducer = persistReducer(persistConfig, reducersRoots);

const epicMiddleware = createEpicMiddleware();
const configureStore = createStore(persistedReducer,
  /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() ,*/
  applyMiddleware(epicMiddleware, /* logger */));
epicMiddleware.run(epicsRoots);

/* const epicMiddleware = createEpicMiddleware();
const createStoreWithMiddleware = applyMiddleware(logger, epicMiddleware)(createStore);
epicMiddleware.run(epicsRoots); */

const persistor = persistStore(configureStore);


export {configureStore, persistor};
