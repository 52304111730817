import styled from "styled-components";

export const EditarLeadModalWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .erro-existe {
    margin-top: 0.3rem;
    color: red;
    display: ${props => props.erroExiste ? "block" : "none"};
  }

  & .erro-servidor {
    margin-top: 0.3rem;
    color: red;
    display: ${props => props.erroServidor ? "block" : "none"};
  }
`;
