
/**
 * Classe que manipula o array de leads.
 *
 * @class LeadsHanlder
 */
class LeadsHanlder {

  
  /**
   * Procura e retorna o lead no array de leads.
   *
   * @static
   * @memberof LeadsHanlder
   * @returns O lead se encontrar, false caso contrário.
   */
  static selecionaLead(leads, lead) {
    const leadSelecionado = leads.find(itemLead => itemLead.lead_id === lead);
    return leadSelecionado === undefined ? false : leadSelecionado;
  }

  static leadJaCadastrado(leads, lead) {
    const leadSelecionado = leads.find(itemLead => itemLead.name.toLocaleLowerCase() === lead.toLocaleLowerCase());
    return leadSelecionado === undefined ? false : leadSelecionado;
  }

  static removeLead(leads, lead) {
    const leadIndexOf = leads.map(itemLead => {return itemLead.lead}).indexOf(lead);
    /* console.log("REMOVE LEADS");
    console.log(leads);
    console.log(lead);
    console.log(leadIndexOf); */
    leads.splice(leadIndexOf, 1);
    return [...leads];
  }

  static removeDocumento(leadSelecionado, tipo, documento) {
    // Pegando o lead que será alterado.
    const leadSelect = {...leadSelecionado};
    if(leadSelect !== false) {
      const tipoLista = leadSelect[tipo];
      const documentoIndexOf = tipoLista.lista.map(itemDoc => {return itemDoc.numero}).indexOf(documento);
      if(documentoIndexOf !== -1) {
        tipoLista.lista.splice(documentoIndexOf, 1);
        leadSelect[tipo] = {...tipoLista};
        // console.log(leadSelect);
        return {...leadSelect};
      }
      else
        return false;
      }
    return false;
  }

  static adicionaDocumento(leadSelecionado, tipo, documento) {
    const leadSelect = {...leadSelecionado};
    if(leadSelecionado !== false) {
      const tipoLista = leadSelect[tipo];
      tipoLista.lista.push(documento);
      return {...leadSelect};
    }
    return false;
  }

  static selecionaDocumento(leadSelecionado, tipo, documento) {
    const leadSelect = {...leadSelecionado};
    const tipoLista = leadSelect[tipo];

    let valor = 0;
    if(tipo === "cpf") {
      valor = documento.cpf;
    }
    else if(tipo === "cnpj") {
      valor = documento.cnpj;
    }
    else {
      valor = documento.ccm;
    }

    // console.log(leadSelect, tipoLista, documento, valor);

    const documentoIndexOf = tipoLista.lista.map(itemDoc => {return itemDoc.numero}).indexOf(valor);
    // console.log(documentoIndexOf, documento);
    // console.log(tipoLista[documentoIndexOf]);
    // console.log(documentoIndexOf);
    if(documentoIndexOf !== -1)
        return tipoLista[documentoIndexOf];
      
    return -1;      
  }

  static filtraListaLeads(leads, valor) {
    if(valor.length > 0) {
      const novaListaLead = leads.filter(lead => lead.name.toLocaleLowerCase().startsWith(valor.toLocaleLowerCase()));
      // console.log(novaListaLead);
      return [...novaListaLead];
    }
    return leads;
  }

  // Percorre a lista de documentos, se algum tiver o status false, retorna false.
  static verificaStatus = (documentos) => {

    if(documentos.length === 0)
      return false;

    for(let i = 0; i < documentos.length; i++) {
      if(documentos[i].processed === false)
        return false;
    }
    return true;
  }

  
}// Fim da classe.

export default LeadsHanlder;