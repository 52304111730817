import React, {Component} from "react";

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";


/**
 * Modal para deletar um lead.
 *
 * @class DeletarLeadModal
 * @extends {Component}
 */
class DeletarLeadModal extends Component {

  /* constructor(props) {
    super(props);
  } */

  // Fecha o modal.
  fechaModal = () => {
    this.props.fechaModal();
  }


  // Deleta o modal.
  deletarLead = () => {
    let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    this.props.deletarLead(leadSelecionado.lead_id);
  }

  componentDidUpdate() {
    let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    if(leadSelecionado === null) {
      this.fechaModal();
    }
  }

  render() {
    let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    leadSelecionado = leadSelecionado === null ? "" : leadSelecionado;
    let deletarLeadLoad = this.props.leadsReducer.get("deletarLeadLoad");
    return(
      <Modal size="mini" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon='trash' content='Deletar Lead' />
        <Modal.Content>
          <p>Tem certeza que deseja deletar {leadSelecionado.lead}?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.fechaModal} disabled={deletarLeadLoad}>
            <Icon name='remove' /> Não
          </Button>
          <Button color='green' onClick={this.deletarLead} loading={deletarLeadLoad} disabled={deletarLeadLoad} >
            <Icon name='checkmark' /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deletarLead: (lead) => {
      dispatch(leadsActions.deletarLead(lead))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletarLeadModal);