import React, {Component} from "react";

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";


/**
 * Modal para arquivar um lead.
 *
 * @class ArquivarLeadModal
 * @extends {Component}
 */
class ArquivarLeadModal extends Component {

  // Fecha o modal.
  fechaModal = () => {
    this.props.arquivarFalseSuccessLead();
    this.props.fechaModal();
  }

  componentWillUnmount() {
    this.fechaModal();
  }

  shouldComponentUpdate(nextProps) {
    if(!this.props.leadsReducer.get("arquivarLeadSuccess") && nextProps.leadsReducer.get("arquivarLeadSuccess")) {
      this.fechaModal();
    }
    return true;
  }

  // Arquivar o lead
  arquivaLead = (lead) => {
    this.props.arquivarLead(lead);
  }

  render() {
    let leadSelecionado = this.props.lead;
    leadSelecionado = leadSelecionado === null || leadSelecionado === undefined ? "" : leadSelecionado;

    const load = this.props.leadsReducer.get("arquivarLeadLoad");
    const fail = this.props.leadsReducer.get("arquivarLeadFail");

    const arquiva_des = leadSelecionado.archived ? "Desarquivar" : "Arquivar";

    return(
      <Modal size="mini" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon={leadSelecionado.archived ? "folder open" : "folder"} content={arquiva_des + " Lead"} />
        <Modal.Content>
          <p>{arquiva_des} Lead: <strong>{leadSelecionado.name}</strong></p>
          {
            fail ? <p style={{color: "red"}}>Erro ao {arquiva_des} Lead. Tente novamente</p>
            :
            null
          }
        </Modal.Content>
        <Modal.Actions>
        <Button color='red' onClick={this.fechaModal} disabled={load} >
          Não
        </Button>
          <Button color='green' onClick={() => this.arquivaLead(leadSelecionado)}
           loading={load} disabled={load}>
            <Icon name='checkmark' /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    arquivarLead: (lead) => {
      dispatch(leadsActions.arquivarLead(lead))
    },
    arquivarFalseSuccessLead: () => {
      dispatch(leadsActions.arquivarLeadFalseSuccess())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArquivarLeadModal);