import React from "react";

import { Input } from 'semantic-ui-react';


const FormCnpjComponent = (props) => {

  return(
    <div className="entrada-cpf">
    <Input label="CNPJ" placeholder={`Número do CNPJ`} value={props.value} fluid maxLength="18"
        onChange={(e) => props.entrada(e.target.value)} type="text" />
    <div style={{textAlign: "center"}}>
      <span style={{display: props.validate ? "none" : "block", color: "red"}}>CNPJ inválido</span>
    </div>
  </div>
  );
}

export default FormCnpjComponent;