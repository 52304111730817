import React, {Component} from "react";

import { Button, Header, Icon, Modal, Input } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";
import LeadHanlder from "../../../services/leadsHandler";
import { EditarLeadModalWrapper } from "./EditarLeadModal.styled";


class EditarLeadModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      novoLeadInput: null,
      erroExiste: false,
    };
    
  }

  // Fecha o modal.
  fechaModal = () => {
    if(this.props.leadsReducer.get("editarLeadSuccess"))
      this.props.editarFalseSuccessLead();
    this.setState({novoLeadInput: null, erroExiste: false}, () => {
      this.props.fechaModal();
    })
  }

  componentWillUnmount() {
    this.fechaModal();
  }

  // Entrada de dados do novo lead.
  novoLeadInputHandler = (valor) => {
    this.setState({novoLeadInput: valor, erroExiste: false});
  }

  // Adiciona Lead.
  editarLead = () => {

    if(this.state.novoLeadInput === null)
      return;
    if(this.state.novoLeadInput.length === 0)
      return;

    const leads = this.props.leadsReducer.get("leads");
    const novoLead = this.state.novoLeadInput;
    const existe = LeadHanlder.leadJaCadastrado(leads, novoLead) === false ? false : true;
    
    if(existe) {
      this.setState({erroExiste: true});
    }

    else {
      this.props.editarLead(novoLead);
    }
  }

  componentDidUpdate() {
    if(this.props.leadsReducer.get("editarLeadSuccess")) {
      this.fechaModal();
    }
  }

  render() {
    const editarLeadFail = this.props.leadsReducer.get("editarLeadFail");
    const editarLeadLoad = this.props.leadsReducer.get("editarLeadLoad");
    const leadAtual = this.props.leadsReducer.get("leadSelecionado");
    const nomeAtual = leadAtual === null ? "" : leadAtual.name
    return(
      <Modal size="mini" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon='file text' content='Editar Lead' />
        <Modal.Content>
          <EditarLeadModalWrapper erroExiste={this.state.erroExiste} erroServidor={editarLeadFail} >
            <Input label='Lead' placeholder={nomeAtual}
            onChange={(e) => this.novoLeadInputHandler(e.target.value)} /* maxLength="29" */ 
            value={this.state.novoLeadInput !== null ? this.state.novoLeadInput : nomeAtual} />
            <p className="erro-existe">Lead já existente</p>
            <p className="erro-servidor">Não foi possível Editar</p>
          </EditarLeadModalWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.editarLead} loading={editarLeadLoad} 
          disabled={editarLeadLoad}>
            <Icon name='checkmark' /> Editar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editarLead: (novoLead) => {
      dispatch(leadsActions.editaLead(novoLead))
    },
    editarFalseSuccessLead: () => {
      dispatch(leadsActions.editaLeadFalseSuccess())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarLeadModal);