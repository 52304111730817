import inovaMind from "./../../assets/logo/inovamindlogo.svg";

export const BORDA_COR = "#efefef";
export const COR_FONTE_TITULO = "#72777a";

export const COR_STATUS_OK = "#28bc4b";
export const COR_STATUS_PENDING = "#f2711c";

export const INOVA_MIND_COLOR = "#c9204b";
export const INOVA_MIND_COLOR_HOVER = "#ad1f43";
export const INOVA_MIND_LOGO = inovaMind;


export const MEDIA_QUERIES_FULL_HD = "1918px";
