import styled from "styled-components";
import { BORDA_COR } from "../../UI/Assets";

export const NavbarWrapper = styled.div`
  border: 1px solid ${BORDA_COR};
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  & .nav-menu {
    flex: 1;
    width: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .nav-menu span {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    line-height: 1.5;
    color: #72777a;
  }

  & .nav-logo {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    margin-top: 1px;
  }

  & .nav-sair {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem;
  }

  & .nav-sair span {
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
    line-height: 1.5;
    color: #72777a;
  }

  & .nav-user {
  }
`;