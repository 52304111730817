export const VALIDA_USUARIO = "VALIDA_USUARIO";
export const INSERE_USUARIO = "INSERE_USUARIO";
export const REMOVE_USUARIO = "REMOVE_USUARIO";
export const FALHA_VALIDACAO = "FALHA_VALIDACAO";

export const validaUsuario = (usuario, senha) => {
  return {
    type: VALIDA_USUARIO,
    payload: {
      usuario: usuario,
      senha: senha,
    }
  };
};

export const insereUsuario = (usuario, senha, token) => {
  return {
    type: INSERE_USUARIO,
    payload: {
      usuario: usuario,
      senha: senha,
      token: token
    }
  };
};

export const removeUsuario = () => {
  return {
    type: REMOVE_USUARIO,
  };
};

export const falhaValidacao = () => {
  return {
    type: FALHA_VALIDACAO,
  };
};
