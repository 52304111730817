import React, {Component} from "react";
import { InfoDadosLeadsWrapper, SelecionaDocumentoWrapper, DocumentoInfoTituloWrapper, DocumentoDadosWrapper } from "./InfoDadosLeads.styled";
import DeletarDocumentoModal from "../../../modais/deletarDocumento/DeletarDocumentoModal";
import ModalConfirm from "../../../modais/ModalConfirm";

import { Icon, Popup, Button } from 'semantic-ui-react';
import Validators from "../../../../services/Validators";
import ListaInfoDadosDocumentosComponent from "./ListaInfoDadosDocumentos";


/**
 * Mostra a situação do cpf, cnpj ou rim.
 *
 * @class InfoDadosLeads
 * @extends {Component}
 */
class InfoDadosLeadsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showDeletarModal: false,
      showModalRenewDocument: false,
    };
  }

  // Fecha o modal
  fechaModal = () => {
    // console.log("fecha modal");
    this.setState({showDeletarModal: false}, () => {
      // console.log(this.state.showDeletarModal);
    });
  }

  // Abre o modal de deletar a lead selecionada.
  showDeleteDeleteModal = () => {
    this.setState({showDeletarModal: true});
  }

  // Adicionar mascara.
  adiconaMascara = (tipo, valor) => {
    // console.log(tipo, valor);
    if(tipo === "cpf") {
      return Validators.mascaraCpf(valor);
    }
    else if(tipo === "cnpj") {
      // console.log(valor);
      return Validators.mascaraCnpj(valor);
    }
    else {
      return Validators.mascaraCcm(valor);
    }
  }

  // Cria uma lista apenas com as info data do documento.
  geraListaDocs = () => {
    const documento = this.props.documento;
    let listaDatas = [];

    for (let elemento in documento) {
      if(elemento.startsWith("data")) {
        listaDatas.push(documento[elemento]);
      }
    }

    listaDatas = listaDatas.filter(item => item !== null);
    return [...listaDatas];
  }

  // Renovar as certidões.
  onClickRenewDocument = () => {
    this.setState({ showModalRenewDocument: true })
  }

  onClickModalRenewDocumentCancel = () => {
    this.setState({ showModalRenewDocument: false })
  }

  onClickModalRenewDocumentConfirm = () => {
    const { onClickRenewDocument } = this.props

    this.setState({ showModalRenewDocument: false })
    onClickRenewDocument()
  }

  render() {
    const documento = this.props.documento;
    // console.log("showDeletarModal: ", this.state.showDeletarModal)
    const listaDatas = this.geraListaDocs();
    // console.log(listaDatas);
    return (
      <InfoDadosLeadsWrapper className="info-dados-leads-wrapper">
        {
          documento === -1 ?
          <SelecionaDocumentoWrapper className="seleciona-documento-wrapper">
             <span>Selecione um Documento</span>
          </SelecionaDocumentoWrapper>
          :
          <React.Fragment>
            <DocumentoInfoTituloWrapper className="documento-info-titulo">
              <span className="numero-documento">
                {documento.tipo.toUpperCase()}: {this.adiconaMascara(documento.tipo, documento.numero)}
              </span>
              <Icon name={documento.processed ? "check circle outline" : "wait"}
                color={documento.processed ? "green" : "orange"} style={{marginLeft:"0.5rem"}} />

              <Popup
                trigger={
                  <Button icon circular compact onClick={this.showDeleteDeleteModal}
                    style={{marginLeft:"1rem", cursor: "pointer"}}>
                    <Icon name="trash" color="red"
                    />
                  </Button>
                }
                content='Deletar Documento'
                position='top center'
                inverted
              />

              <Popup
                trigger={
                  <Button icon circular compact onClick={this.onClickRenewDocument}>
                    <Icon style={{cursor: "pointer", marginRight: "0.8rem"}} name="refresh" color="black"
                    />
                  </Button>
                }
                content='Renovar Certidões'
                position='top center'
                inverted
              />

              <DeletarDocumentoModal showModal={this.state.showDeletarModal}
                fechaModal={this.fechaModal}
              />

              <ModalConfirm
                show={this.state.showModalRenewDocument}
                onCancel={this.onClickModalRenewDocumentCancel}
                onConfirm={this.onClickModalRenewDocumentConfirm}
                iconId={'refresh'}
                title={'Renovar Certidões'}
                description={'Tem certeza que deseja renovar as certidões?'}
              />

            </DocumentoInfoTituloWrapper>
            <div className="documento-dados-nome">
              <span>{documento.fullname}</span>
            </div>
            <DocumentoDadosWrapper>
              <div className="documento-dados-lista">
                <ListaInfoDadosDocumentosComponent lista={listaDatas} />
              </div>
            </DocumentoDadosWrapper>
          </React.Fragment>
        }
      </InfoDadosLeadsWrapper>
    );
  }
}

export default InfoDadosLeadsComponent;
