import styled from "styled-components";
import { BORDA_COR, COR_FONTE_TITULO } from "./../../../UI/Assets";

export const InfoLeadsWrapper = styled.div`
  width: 380px;
  border: solid ${BORDA_COR};
  border-width: 0 1px 0 0;

  padding: 0.5rem 0 0 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const SelecionaLeadWrapper = styled.div`
  flex: 1;
  border: solid ${BORDA_COR};
  border-width: 0 1px 0 0;

  padding: 0.5rem 0 0 0;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: stretch;

  & span {
    display: inline-flex;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: ${COR_FONTE_TITULO};

  }
`;

export const InfoLeadsTituloWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .titulo-control {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .titulo-name {
    width: 180px;
    overflow-x: hidden;
    white-space: nowrap;
    text-align: center;
  }

  & .titulo-lead {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: ${COR_FONTE_TITULO};
    margin-right: 0.3rem;
    text-align: center;
  }
`;

export const InfoLeadsDocsConteudoWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const InfoLeadDocumentoWrapper = styled.div`
  margin-top: 1.5rem;
`;

/* export const DocumentoTituloWrapper = styled.div`
  text-align: center;
  cursor: pointer;

  & span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: ${props => props.statusCor ? COR_STATUS_OK : COR_STATUS_PENDING};
    margin-right: 0.3rem;
  }
`; */

export const DocumentoConteudoWrapper = styled.div`
  display: ${props => props.showDisplay ? "block" : "none"};

  & .total-documentos {
    text-align: center;
    margin: 0.8rem 0.5rem;
    padding: 0.5rem 0;
    border: 1px solid ${BORDA_COR};
    border-width: 1px 0 0 0;
  }

  & .total-documentos span {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${props => props.total ? "red" : COR_FONTE_TITULO};
  }
`;

export const DocumentoSelectWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  padding: 0.3rem 0.5rem;
`;

export const MaximoAtingidoWrapper = styled.div`
  width: 100%;
  text-align: center;

  & span {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: red;
  }
`;

export const ActionsButtonsLeadsWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-right: 0.2rem; 
  justify-content: flex-end;

  & button {
    margin-right: 0.2rem;
  }
`;

export const FileActionsWrapper = styled.div`
  margin-left: 0.6rem;
  display: flex;
`;
