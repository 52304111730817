import React, {Component} from "react";

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";
import Validators from "../../../services/Validators";


/**
 * Modal para deletar um lead.
 *
 * @class DeletarDocumentoModal
 * @extends {Component}
 */
class DeletarDocumentoModal extends Component {

  /* constructor(props) {
    super(props);
  } */

  // Fecha o modal.
  fechaModal = () => {
    // console.log("VAI FECHAR");
    this.props.fechaModal();
  }


  // Deleta o modal.
  deletarDocumento = () => {
    let leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    let documentoSelecionado = this.props.leadsReducer.get("selecionaDocumento");
    this.props.deletarDocumento(leadSelecionado.lead_id, documentoSelecionado.tipo,
      documentoSelecionado.numero);
  }


  /* getSnapshotBeforeUpdate(prevProps, prevState) {
    let selecionaDocumento = prevProps.leadsReducer.get("selecionaDocumento");
    console.log("getSnapshotBeforeUpdate", selecionaDocumento);
    return true;
  }

  componentDidUpdate() {
    let selecionaDocumento = this.props.leadsReducer.get("selecionaDocumento");
    console.log("componentDidUpdate", selecionaDocumento);
    if(selecionaDocumento === null) {
      this.fechaModal();
    }
  } */

  componentWillUnmount() {
    this.fechaModal();
  }

  // Adiciona mascara. 
  adicionaMacaraValor = (tipo, valor) => {
    if(tipo === "cpf") {
      return Validators.mascaraCpf(valor);
    }
    else if(tipo === "cnpj") {
      return Validators.mascaraCnpj(valor);
    }
    else {
      return Validators.mascaraCcm(valor);
    }
  }

  render() {
    let documentoSelecionado = this.props.leadsReducer.get("selecionaDocumento");
    documentoSelecionado = documentoSelecionado === null ? "" : documentoSelecionado;
    let deletarDocumentoLoad = this.props.leadsReducer.get("deletarDocumentoLoad");
    // console.log("deletar documento modal: ", documentoSelecionado);
    return(
      <Modal size="tiny" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon='trash' content={`Deletar ${documentoSelecionado.tipo}`} />
        <Modal.Content>
          <p><span>Tem certeza que deseja deletar</span> 
            <strong> {this.adicionaMacaraValor(documentoSelecionado.tipo, documentoSelecionado.numero)}?</strong>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.fechaModal} disabled={deletarDocumentoLoad}>
            <Icon name='remove' /> Não
          </Button>
          <Button color='green' onClick={this.deletarDocumento} loading={deletarDocumentoLoad}
           disabled={deletarDocumentoLoad} >
            <Icon name='checkmark' /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deletarDocumento: (lead, tipo, documento) => {
      dispatch(leadsActions.deletarDocumento(lead, tipo, documento))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletarDocumentoModal);