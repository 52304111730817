/* import { leads } from "./fakeDadosLeads";
import LeadHanlder from "./../services/leadsHandler";
import LeadsHanlder from "./../services/leadsHandler"; */
// import LeadsServidor from "./leadsServidor";
import axios from "axios";

const SERVER = process.env.REACT_APP_API_SERVER;
const AUTH = process.env.REACT_APP_API_KEY;
const HEADER_AUTH = process.env.NODE_ENV === 'development' ? 'Authorization' : 'x-api-key'


const LOGIN = `${SERVER}/login`;

const GET_LEADS = `${SERVER}/leads`;
const POST_LEAD = `${SERVER}/leads`;
const PUT_EDITA_LEAD = `${SERVER}/leads`;
const RENEW_CERTIFICATES_LEAD = `${SERVER}/leads/renew`;
// const DELETE_LEAD = `${SERVER}/leads`;

class ApiService {

  // Faz a autenticação do usuário.
  static validaUsuario = (usuario, senha) => {
    return new Promise((resolve) => {

      const data = {
        username: usuario,
        password: senha
      }

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      config.headers[HEADER_AUTH] = AUTH

      axios.post(LOGIN, data, config).then(res => {
        resolve(res.data.user_token);
      })
      .catch(erro => {
        resolve(null);
      })
    })
  }

  static renewCertificatesLead = (userToken, leadId) => {
    const data = {}

    const config = {
      headers: {
        "Content-type": "application/json",
        "user-token": userToken,
      },
    }
    config.headers[HEADER_AUTH] = AUTH


    return axios.post(`${RENEW_CERTIFICATES_LEAD}/${leadId}`, data, config)
  }

  static renewCertificatesDocument = (userToken, leadId, type, docId) => {
    const data = {}

    const config = {
      headers: {
        "Content-type": "application/json",
        "user-token": userToken,
      },
    }
    config.headers[HEADER_AUTH] = AUTH


    return axios.post(`${RENEW_CERTIFICATES_LEAD}/${leadId}/${type}/${docId}`, data, config)
  }

  // Faz a requisição dos leads.
  static getLeads = (token) => {
    return new Promise((resolve) => {

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.get(GET_LEADS, config).then(res => {
        resolve(res.data);
      }).catch(() => {
        resolve(null);
      })

    });
  }

  // Insere um novo lead
  static postLead = (token, name) => {
    return new Promise((resolve) => {

      const data = {
        name: name,
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.post(POST_LEAD, data, config).then(res => {
        resolve(res.data);
        // resolve(res.data);
      })
      .catch(() => {
        resolve(null);
      })
    })
  }

  // Remove um lead.
  static removeLead = (token, lead) => {
    return new Promise((resolve) => {
      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.delete(POST_LEAD + "/" + lead, config).then(res => {
        resolve(true);
      })
      .catch(() => {
        resolve(null);
      })

    })
  }

  // Retorna todos os cpf's do lead
  static getLeadCpf = (token, lead_id) => {
    return new Promise((resolve) => {
      const GET_LEAD_CPFS = GET_LEADS + `/${lead_id}/cpfs`;
      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.get(GET_LEAD_CPFS, config).then(res => {
        const cpfs = [];
        res.data.forEach(item => {
          cpfs.push({
            ...item,
            numero: item.cpf,
            name: item.name,
            processed: item.processed,
          })
        });
        resolve(cpfs);
      }).catch(() => {
        resolve(null);
      })
    })
  }

  // Retorna todos os cnpj's do lead.
  static getLeadCnpj = (token, lead_id) => {
    return new Promise((resolve) => {
      const GET_LEAD_CNPJS = GET_LEADS + `/${lead_id}/cnpjs`;
      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.get(GET_LEAD_CNPJS, config).then(res => {
        const cnpjs = [];
        res.data.forEach(item => {
          cnpjs.push({
            ...item,
            numero: item.cnpj,
            name: item.fullname,
            processed: item.processed,
          })
        });
        resolve(cnpjs);
      }).catch(erro => {
        resolve(null);
      })
    })
  }

  // Retorna todos os ccm's do lead
  static getLeadCcm = (token, lead_id) => {
    return new Promise((resolve) => {
      const GET_LEAD_CCM = GET_LEADS + `/${lead_id}/properties`;
      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH

      axios.get(GET_LEAD_CCM, config).then(res => {
        const ccms = [];
        res.data.forEach(item => {
          ccms.push({
            ...item,
            numero: item.property,
            name: item.name,
            processed: item.processed,
          })
        });
        resolve(ccms);
      }).catch(() => {
        resolve(null);
      })
    })
  }

 /*  // Pega as informações do lead.
  static getLead = (lead) => {
    return new Promise((resolve) => {
     setTimeout(() => {
        // Encontra o lead.
      const leadsServidor = new LeadsServidor();
      const leadSelecionado = leadsServidor.selecionaLead(lead);
      resolve(leadSelecionado);
     }, 2000);
    })
  } */

  // Remove um documento.
  static removeDocumento = (token, lead_id, tipoDocumento, documento_id) => {
    return new Promise((resolve) => {

      let tipo = tipoDocumento === "ccm" ? "propertie" : tipoDocumento;
      const GET_DELETE_DOCUMENTO = GET_LEADS + `/${lead_id}/${tipo}s/${documento_id}`;

      // console.log(lead_id, tipoDocumento, documento_id);
      // console.log(GET_DELETE_DOCUMENTO);

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.delete(GET_DELETE_DOCUMENTO, config).then(res => {
        // console.log(res);
        resolve(true);
      })
      .catch(() => {
        resolve(null);
      })
    })
  }

  // Adicionar um novo documento.
  static adicionaDocumento = (token, lead_id, tipoDocumento, documento, serasaControl) => {
    return new Promise((resolve) => {
      let POST_DOCUMENTO = null;

      // console.log(documento);

      let data = {};
      if(tipoDocumento === "cpf") {
        data = {
          cpf: documento.cpf,
          rg: documento.rg,
          enabled_serasa: serasaControl,
        }
        POST_DOCUMENTO =  GET_LEADS + `/${lead_id}/cpfs`;
      }

      else if(tipoDocumento === "cnpj"){
        data = {
          cnpj: documento.cnpj,
          enabled_serasa: serasaControl,
        }
        POST_DOCUMENTO =  GET_LEADS + `/${lead_id}/cnpjs`;
      }

      else {
        data = {
          property: documento.ccm
        }
        POST_DOCUMENTO =  GET_LEADS + `/${lead_id}/properties`;
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH


      axios.post(POST_DOCUMENTO, data, config).then(res => {
        // console.log(res);
        resolve(res.data);
      })
      .catch(() => {
        resolve(null);
      })
    })
  }

  // Edita o nome do lead.
  static editaLead = (token, leadId, novoNome) => {
    return new Promise((resolve) => {

      const data = {
        name: novoNome
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH

      const ROTA = `${PUT_EDITA_LEAD}/${leadId}`
      axios.put(ROTA , data, config).then(res => {
        resolve(res.data);
        // resolve(res.data);
      })
      .catch(() => {
        resolve(null);
      })
    });
  }

  // Arquiva/Desarquiva o lead.
  static arquivaLead = (token, leadId, status) => {
    return new Promise((resolve) => {

      const data = {
        archived: !status
      }

      console.log(data);

      const config = {
        headers: {
          "Content-type": "application/json",
          "user-token": token
        },
      };
      config.headers[HEADER_AUTH] = AUTH

      const ROTA = `${PUT_EDITA_LEAD}/${leadId}`
      axios.put(ROTA , data, config).then(res => {
        resolve(res.data);
        // resolve(res.data);
      })
      .catch(() => {
        resolve(null);
      })
    });
  }

}// Fim da classe

export default ApiService;
