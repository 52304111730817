import { Map } from 'immutable';

import { REQUEST_LEADS, SUCCESS_LEADS, FAIL_LEADS, SELECIONA_LEAD,
SELECIONA_SUCCESS_LEAD, SELECIONA_FAIL_LEAD, ADICIONAR_LEAD, ADICIONAR_SUCCESS_LEAD, 
ADICIONAR_FAIL_LEAD, DELETAR_LEAD, DELETAR_SUCCESS_LEAD, DELETAR_FAIL_LEAD,
SELECIONA_DOCUMENTO, DELETAR_DOCUMENTO, DELETAR_SUCCESS_DOCUMENTO, 
DELETAR_FAIL_DOCUMENTO, ADICIONAR_DOCUMENTO, ADICIONAR_SUCCESS_DOCUMENTO, 
ADICIONAR_FAIL_DOCUMENTO, ZERA_REDUCER, ADICIONAR_FALSE_SUCCESS_LEAD,
ADICIONAR_FALSE_SUCCESS_DOCUMENTO, EDITAR_LEAD, EDITAR_LEAD_SUCCESS, EDITAR_LEAD_FAIL,
EDITAR_LEAD_FALSE_SUCCESS, ARQUIVAR_LEAD, ARQUIVAR_LEAD_SUCCESS,
ARQUIVAR_LEAD_FAIL, ARQUIVAR_LEAD_FALSE_SUCCESS, LIMPA_LEAD } from "./leads.actions";

let leadsInitial = Map({
  leads: null,
  load: false,
  fail: false,

  leadSelecionado: null,
  loadSelecionaLead: false,
  failSelecionaLead: false,

  adicionarLeadSuccess: false,
  adicionarLeadLoad: false,
  adicionarLeadFail: false,

  deletarLeadLoad: false,
  deletarLeadFail: false,

  selecionaDocumento: null,

  deletarDocumentoLoad: false,
  deletarDocumentoFail: false,

  adicionarDocumentoSuccess: false,
  adicionarDocumentoLoad: false,
  adicionarDocumentoFail: false,

  editarLeadSuccess: false,
  editarLeadLoad: false,
  editarLeadFail: false,

  arquivarLeadSuccess: false,
  arquivarLeadLoad: false,
  arquivarLeadFail: false,

});

const leadsReducer = (state = leadsInitial, action) => {
  switch (action.type) {

    case REQUEST_LEADS:
      state = state.set("load", true).set("fail", false);
    break;

    case SUCCESS_LEADS:
      // const selecionadoInicial = state.get("leadSelecionado");
      state = state.set("leads", action.payload.leads).set("load", false)
      .set("fail", false);
    break;

   case FAIL_LEADS:
      state = state.set("load", false).set("fail", true);
    break;


    /* ************************************** SELECIONAR LEAD ************************************** */

    case SELECIONA_LEAD:
      state = state.set("loadSelecionaLead", true).set("failSelecionaLead", false);
    break;

    case SELECIONA_SUCCESS_LEAD:
      state = state.set("leadSelecionado", action.payload.lead).set("loadSelecionaLead", false)
      .set("failSelecionaLead", false).set("selecionaDocumento", action.payload.update)
      .set("leads", action.payload.leads);
    break;

    case SELECIONA_FAIL_LEAD:
      state = state.set("failSelecionaLead", true).set("loadSelecionaLead", false).set("leadSelecionado", null);
    break;

    case LIMPA_LEAD:
      state = state.set("leadSelecionado", null);
    break;


    /* ************************************** Adicionar LEAD ************************************** */

    case ADICIONAR_LEAD:
      state = state.set("adicionarLeadLoad", true).set("adicionarLeadFail", false);
    break;

    case ADICIONAR_SUCCESS_LEAD:
      state = state.set("leads", action.payload.leads).set("adicionarLeadLoad", false)
      .set("adicionarLeadFail", false).set("leadSelecionado", action.payload.leadSelecionado)
      .set("selecionaDocumento", null).set("adicionarLeadSuccess", true);
    break;

    case ADICIONAR_FAIL_LEAD:
      state = state.set("adicionarLeadFail", true).set("adicionarLeadLoad", false);
    break;

    case ADICIONAR_FALSE_SUCCESS_LEAD:
      state = state.set("adicionarLeadSuccess", false);
    break;


    /* ************************************** DELETAR LEAD ************************************** */
    case DELETAR_LEAD:
      state = state.set("deletarLeadLoad", true).set("deletarLeadFail", false);
    break;

    case DELETAR_SUCCESS_LEAD:
      state = state.set("deletarLeadLoad", false).set("deletarLeadFail", false)
      .set("leadSelecionado", null).set("leads", action.payload.leads).set("selecionaDocumento", null);
    break;

    case DELETAR_FAIL_LEAD:
      state = state.set("deletarLeadFail", true).set("deletarLeadLoad", false);
    break;


    /* ************************************** SELECIONA DOCUMENTO ************************************** */
    case SELECIONA_DOCUMENTO:
      state = state.set("selecionaDocumento", action.payload.documento);
    break;


    /* ************************************** DELETAR DOCUMENTO ************************************** */
    case DELETAR_DOCUMENTO:
      state = state.set("deletarDocumentoLoad", true).set("deletarDocumentoFail", false);
    break;

    case DELETAR_SUCCESS_DOCUMENTO:
      state = state.set("deletarDocumentoLoad", false).set("deletarDocumentoFail", false)
      .set("selecionaDocumento", null).set("leadSelecionado", action.payload.novoLeadSelecionado)
      .set("leads", action.payload.leads);
      // console.log("reducer state: ", state.get("selecionaDocumento"))
    break;

    case DELETAR_FAIL_DOCUMENTO:
      state = state.set("deletarDocumentoLoad", false).set("deletarDocumentoFail", true);
    break;

    /* ************************************** ADICIONAR DOCUMENTO ************************************** */
    case ADICIONAR_DOCUMENTO:
      state = state.set("adicionarDocumentoLoad", true).set("adicionarDocumentoFail", false);
    break;

    case ADICIONAR_SUCCESS_DOCUMENTO:
      state = state.set("adicionarDocumentoLoad", false).set("adicionarDocumentoFail", false)
      .set("selecionaDocumento", action.payload.novoDocumento)
      .set("leadSelecionado", action.payload.novoLeadSelecionado).set("adicionarDocumentoSuccess", true)
      .set("leads", action.payload.leads);
    break;

    case ADICIONAR_FAIL_DOCUMENTO:
      state = state.set("adicionarDocumentoLoad", false).set("adicionarDocumentoFail", true);
    break;

    case ADICIONAR_FALSE_SUCCESS_DOCUMENTO:
      state = state.set("adicionarDocumentoSuccess", false);
    break;


    /* ************************************** ZERA REDUCER ************************************** */
    case ZERA_REDUCER:
      state = state.set("leadSelecionado", null).set("selecionaDocumento", null);
    break;


    /* ************************************** EDITAR LEAD ************************************** */
    case EDITAR_LEAD:
      state = state.set("editarLeadLoad", true).set("editarLeadSuccess", false).set("editarLeadFail", false);
    break;

    case EDITAR_LEAD_SUCCESS:
      state = state.set("editarLeadLoad", false).set("editarLeadSuccess", true).set("editarLeadFail", false)
      .set("leads", action.payload.leads).set("leadSelecionado", action.payload.leadSelecionado);
    break;

    case EDITAR_LEAD_FAIL:
      state = state.set("editarLeadLoad", false).set("editarLeadSuccess", false).set("editarLeadFail", true);
    break;

    case EDITAR_LEAD_FALSE_SUCCESS:
      state = state.set("editarLeadLoad", false).set("editarLeadSuccess", false).set("editarLeadFail", false);
    break;

  
    /* ************************************** ARQUIVAR LEAD ************************************** */
    case ARQUIVAR_LEAD:
      state = state.set("arquivarLeadLoad", true).set("arquivarLeadSuccess", false)
      .set("arquivarLeadFail", false);
    break;

    case ARQUIVAR_LEAD_SUCCESS:
      state = state.set("arquivarLeadLoad", false).set("arquivarLeadSuccess", true)
      .set("arquivarLeadFail", false).set("leads", action.payload.leads)
      .set("leadSelecionado", action.payload.leadSelecionado);
    break;

    case ARQUIVAR_LEAD_FAIL:
      state = state.set("arquivarLeadLoad", false).set("arquivarLeadSuccess", false)
      .set("arquivarLeadFail", true);
    break;

    case ARQUIVAR_LEAD_FALSE_SUCCESS:
      state = state.set("arquivarLeadLoad", false).set("arquivarLeadSuccess", false)
      .set("arquivarLeadFail", false);
    break;


    default:
      break;
  }

  return state;

}// Fim do reducers

export default leadsReducer;