import styled from "styled-components";
import { BORDA_COR } from "../../UI/Assets";

export const LeadsWrapper = styled.div`
  border: solid ${BORDA_COR};
  border-width: 0 0 1px 0;

  width: 100%;
  margin-bottom: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: stretch;

  
`;

export const ErroCarregarLeadWrapper = styled.div`
  display: ${props => props.show ? "block" : "none"};
  position: absolute;
  bottom: 5%;
  right: 3%;
`;
