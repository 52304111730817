import React, {Component} from "react";
import { InfoLeadsWrapper, InfoLeadsTituloWrapper, InfoLeadDocumentoWrapper,
  DocumentoConteudoWrapper, InfoLeadsDocsConteudoWrapper, DocumentoSelectWrapper, SelecionaLeadWrapper, MaximoAtingidoWrapper, ActionsButtonsLeadsWrapper, FileActionsWrapper } from "./InfoLeads.styled";
import { Icon, Select, Popup, Button } from "semantic-ui-react";
import ListarDocumentosComponent from "./ListarDocumentos";
import BotaoInovaMind from "../../../UI/BotaoInovaMind";
import AdicionarDocumentoModal from "../../../modais/adicionarDocumento/AdicionarDocumentoModal";
import CarregandoLeadsModal from "../../../modais/carregandoLeads/CarregandoLeadsModal";
import ModalConfirm from "../../../modais/ModalConfirm";
import GerarZipModal from "../../../modais/gerarZipModal/GerarZipModal";


/**
 * Informação do Lead e listagem de todos os cpf's, cnpj's e rim's
 *
 * @class InfoLeadsComponent
 * @extends {Component}
 */
class InfoLeadsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      documento: "cpf",
      showModalAddDocumento: false,
      showModalGerarZip: false,
      showModalRenewLead: false,
      // showDeletarModal: false,
    };
  }

  // Abre o modal para geração do zip.
  showModalGerarZip = () => {
    this.setState({showModalGerarZip: true});
  }

  // Fecha o modal de gerar zip.
  fechaModalGerarZip = () => {
    this.setState({showModalGerarZip: false});
  }

  // Abre o modal de adicionar novo documento.
  showAddDocumentoModal = () => {
    this.setState({showModalAddDocumento: true});
  }

  // Fecha o modal
  fechaModal = () => {
    this.setState({showModalAddDocumento: false});
  }


  // Manda para o pai LeadsComponent o documento selecionado.
  selecionarDocumento = (documento) => {
    this.props.selecionarDocumento(documento);
  }

  // Seleciona o tipo de documento
  selecDocumento = (e, { value }) => {
    // console.log(value);
    if(value !== this.state.documento) {
      this.setState({documento: value});
    }
  }

  // Atualiza o lead.
  atualizaLeadHandler = (lead_id) => {
    this.props.atualizaLead(lead_id);
  }

  // Renovar as certidões.
  onClickRenewLead = () => {
    this.setState({ showModalRenewLead: true })
  }

  onClickModalRenewLeadCancel = () => {
    this.setState({ showModalRenewLead: false })
  }

  onClickModalRenewLeadConfirm = () => {
    this.setState({ showModalRenewLead: false })
    const { onClickRenewLead } = this.props
    onClickRenewLead()
  }

  // Renderiza as informações do Lead na tela.
  infoLeadRender = () => {

    const leadSelecionadoLoad = this.props.leadSelecionadoLoad;

    if(leadSelecionadoLoad) {
      return(
        <CarregandoLeadsModal />
      );
    }

    else {
      const leadSelecionado = this.props.leadSelecionado;
      this.documentosOptions = [
        {key: "CPF", value: "cpf", text:(
            <div>
              <Icon name="user" color={leadSelecionado.cpf.processed ? "green" : "orange"} />
              <span>CPF</span>
            </div>
          ),
        },
        {key: "CNPJ", value: "cnpj", text:(
            <div>
              <Icon name="suitcase" color={leadSelecionado.cnpj.processed ? "green" : "orange"} />
              <span>CNPJ</span>
          </div>
          ),
        },
        {key: "CCM", value: "ccm", text:(
            <div>
              <Icon name="building" color={leadSelecionado.ccm.processed ? "green" : "orange"} />
              <span>CCM</span>
            </div>
          ),
        },
      ];

      const total = leadSelecionado[this.state.documento].lista.length;
      const totalBlock = total >= this.props.maximoDocumentos ? true : false;
      // console.log(totalBlock, total, this.props.maximoDocumentos);

      return(
          <React.Fragment>
          <InfoLeadsTituloWrapper className="info-lead-titulo">
            <FileActionsWrapper className="file-actions-wrapper">
              <Popup
                trigger={
                  <Button icon circular compact onClick={this.showModalGerarZip}>
                    <Icon style={{cursor: "pointer", marginRight: "0.9rem"}} name="file pdf outline" color="black"
                  />
                  </Button>
                }
                content='Baixar Documentos'
                position='top center'
                inverted
              />
            </FileActionsWrapper>

            <Popup
              trigger={
                <Button icon circular compact onClick={this.onClickRenewLead}>
                  <Icon style={{cursor: "pointer", marginRight: "0.8rem"}} name="refresh" color="black"
                  />
                </Button>
              }
              content='Renovar Certidões'
              position='top center'
              inverted
            />
            <div className="titulo-control">
              <div className="titulo-name">
              <Icon name={leadSelecionado.processed ? "check circle outline" : "wait"}
                color={leadSelecionado.processed ? "green" : "orange"} />
                {
                  leadSelecionado.name.length > 20
                  ?
                  <Popup
                    trigger={
                      <span className="titulo-lead">
                        {leadSelecionado.name}
                      </span>
                    }
                    content={leadSelecionado.name}
                    position='bottom left'
                    inverted
                  />
                  :
                  <span className="titulo-lead">
                    {leadSelecionado.name}
                  </span>
                }
              </div>
            </div>
            <ActionsButtonsLeadsWrapper>
              <Popup
                trigger={
                  <Button icon circular compact onClick={() => this.atualizaLeadHandler(leadSelecionado.lead_id)}>
                    <Icon style={{cursor: "pointer", marginRight: "0.8rem"}} name="repeat" color="black"
                    />
                  </Button>
                }
                content='Recarregar Lead'
                position='top center'
                inverted
              />

              <Popup
                trigger={
                 <Button icon circular compact onClick={this.props.editaLeadSelecionado}>
                   <Icon style={{cursor: "pointer", marginRight: "0.8rem"}} name="edit outline" color="black"
                    />
                 </Button>
                }
                content='Editar Lead'
                position='top center'
                inverted
              />

              <Popup
                trigger={
                  <Button icon circular compact onClick={this.props.deletarLeadSelecionado}>
                    <Icon style={{cursor: "pointer"}} name="trash" color="red"
                    />
                  </Button>
                }
                content='Deletar Lead'
                position='top center'
                inverted
              />

            </ActionsButtonsLeadsWrapper>
          </InfoLeadsTituloWrapper>

          <DocumentoSelectWrapper>
            <Select placeholder='Seleciona o documento' options={this.documentosOptions}
            value={this.state.documento} onChange={this.selecDocumento} fluid/>
          </DocumentoSelectWrapper>

          <InfoLeadsDocsConteudoWrapper className="info-leads-docs-conteudo">
            <InfoLeadDocumentoWrapper className="info-lead-documento">
              <DocumentoConteudoWrapper className="documento-conteudo" showDisplay={true}
              total={totalBlock}>
                <ListarDocumentosComponent documentos={leadSelecionado[this.state.documento].lista}
                  documentoSelecionado={this.props.documentoSelecionado}
                  tipoDocumento={this.state.documento}
                  selecionarDocumento={this.selecionarDocumento}/>
                <div className="total-documentos">
                  <span>Total - {total}</span>
                </div>
              </DocumentoConteudoWrapper>
          </InfoLeadDocumentoWrapper>

          </InfoLeadsDocsConteudoWrapper>

          {
            totalBlock ?
            <MaximoAtingidoWrapper>
              <span>Máximo atingido {this.totalMax}</span>
            </MaximoAtingidoWrapper>
            :
            <div style={{textAlign: "center", margin: " 0 0.5rem 0.5rem 0.5rem"}}>
            <BotaoInovaMind size="mini" content={`Adicionar ${this.state.documento.toUpperCase()}`}
            onClick={this.showAddDocumentoModal}/>
          </div>
          }

          <AdicionarDocumentoModal showModal={this.state.showModalAddDocumento}
          fechaModal={this.fechaModal} tipo={this.state.documento} />

          <GerarZipModal
            showModalGerarZip={this.state.showModalGerarZip}
            fechaModal={this.fechaModalGerarZip}
          />

          <ModalConfirm
            show={this.state.showModalRenewLead}
            onCancel={this.onClickModalRenewLeadCancel}
            onConfirm={this.onClickModalRenewLeadConfirm}
            iconId={'refresh'}
            title={'Renovar Certidões'}
            description={'Tem certeza que deseja renovar todas as certidões?'}
          />

        </React.Fragment>
      );

    }

  }

  /* ****************************** RENDER ****************************** */

  render() {
    const leadSelecionado = this.props.leadSelecionado;
    const leadSelecionadoLoad = this.props.leadSelecionadoLoad;
    // console.log(leadSelecionado);
    return (
      <React.Fragment>
        {
          leadSelecionado === null && leadSelecionadoLoad === false ?
            <SelecionaLeadWrapper>
              <span className="selecione-lead">Selecione um Lead</span>
            </SelecionaLeadWrapper>
          :
          <InfoLeadsWrapper className="info-leads-wrapper">
            {this.infoLeadRender()}
          </InfoLeadsWrapper>
        }
      </React.Fragment>
    );
  }
}

export default InfoLeadsComponent;
