import React, {Component} from "react";

import { LeadsWrapper, ErroCarregarLeadWrapper } from "./Leads.styled";
import InfoLeadsComponent from "./infoLeads/InfoLeads";
import InfoDadosLeadsComponent from "./infoDadosLeads/InfoDadosLeads";

import { connect } from 'react-redux';
import * as leadsActions from "./../../../redux/leads/leads.actions";
import DeletarLeadModal from "../../modais/deletarLead/DeletarLeadModal";

import { Message } from "semantic-ui-react";
import EditarLeadModal from "../../modais/editarLeadModal/EditarLeadModal";
// import ArquivarLeadModal from "../../modais/arquivarLead/ArquivarLead";
import ApiService from "./../../../services/apiService";


/**
 * Container para o Leads e suas informações, e mostra os dados do cpf, cnpn e rim.
 *
 * @class LeadsComponent
 * @extends {Component}
 */
class LeadsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showDeletarModal: false,
      showEditaLeadModal: false,
    };
  }

  // Atualiza o Documento selecionado.
  novoDocumentoSelecionado = (documento) => {
    this.props.selecionaDocumento(documento);
  }

  // Abre o modal de editar o lead.
  showEditaLeadModal = () => {
    // console.log("EDITAR LEAD")
    this.setState({showEditaLeadModal: true});
  }

  // Fecha o modal de editar o lead.
  fechaModalEditaLead = () => {
    this.setState({showEditaLeadModal: false});
  }

  // Abre o modal de deletar a lead selecionada.
  showDeleteLeadSelecionado = () => {
    this.setState({showDeletarModal: true});
  }

  // Fecha o Modal.
  fechaModal = () => {
    this.setState({showDeletarModal: false});
  }

  // Deleta o lead selecionado.
  /* deletarLeadSelecionado = () => {
    const leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    console.log(leadSelecionado)
  } */

  // Atualiza o lead
  atualizaLead = (lead) => {
    this.props.selecionaLead(lead, true);
  }

  // Renovar as certidões.
  onClickRenewLead = () => {
    const { leadsReducer, authReducer } = this.props
    const leadId = leadsReducer.get("leadSelecionado").lead_id;
    const token = authReducer.get('token');

    ApiService.renewCertificatesLead(token, leadId)
    .then(res => {
      this.atualizaLead(leadId);
    }).catch(error => {
      console.error(error)
      alert('Houve um erro ao renovar as certidões.')
    })
  }

  onClickRenewDocument = () => {
    const { leadsReducer, authReducer } = this.props
    const token = authReducer.get('token');
    const leadId = leadsReducer.get("leadSelecionado").lead_id;
    const documentoSelecionado = leadsReducer.get("selecionaDocumento");

    let type = null
    let docId = null
    if (documentoSelecionado.hasOwnProperty('cpf')) {
      type = 'cpf'
      docId = documentoSelecionado['cpf']
    }
    else if (documentoSelecionado.hasOwnProperty('cnpj')) {
      type = 'cnpj'
      docId = documentoSelecionado['cnpj']
    }
    else {
      type = 'property'
      docId = documentoSelecionado['property']
    }

    ApiService.renewCertificatesDocument(token, leadId, type, docId)
    .then(res => {
      this.atualizaLead(leadId);
    }).catch(error => {
      console.error(error)
      alert('Houve um erro ao renovar as certidões.')
    })
  }

  render() {
    const leadSelecionado = this.props.leadsReducer.get("leadSelecionado");
    const leadSelecionadoLoad = this.props.leadsReducer.get("loadSelecionaLead");
    let documentoSelecionado = this.props.leadsReducer.get("selecionaDocumento");
    const maximoDocumentos = this.props.authReducer.get("totalMax");
    documentoSelecionado = documentoSelecionado === null ? -1 : documentoSelecionado;

    const failSelecionaLead = this.props.leadsReducer.get("failSelecionaLead");

    return (
      <LeadsWrapper className="leads-wrapper">
        <InfoLeadsComponent
          leadSelecionado={leadSelecionado}
          leadSelecionadoLoad={leadSelecionadoLoad}
          selecionarDocumento={this.novoDocumentoSelecionado}
          documentoSelecionado={documentoSelecionado}
          deletarLeadSelecionado={this.showDeleteLeadSelecionado}
          editaLeadSelecionado={this.showEditaLeadModal}
          maximoDocumentos={maximoDocumentos}
          atualizaLead={this.atualizaLead}
          arquivarLead={this.showArquivarLeadHandler}
          onClickRenewLead={this.onClickRenewLead}
        />

        <InfoDadosLeadsComponent
          documento={documentoSelecionado}
          onClickRenewDocument={this.onClickRenewDocument}
        />

        <DeletarLeadModal
         showModal={this.state.showDeletarModal}
         fechaModal={this.fechaModal}
        />

        <EditarLeadModal
          showModal={this.state.showEditaLeadModal}
          fechaModal={this.fechaModalEditaLead}
        />

        <ErroCarregarLeadWrapper show={failSelecionaLead}>
          <Message negative>
            <Message.Header>Não foi possível carregar o lead</Message.Header>
              <p>Tente novamente</p>
          </Message>
        </ErroCarregarLeadWrapper>

      </LeadsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
    authReducer: state.authReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selecionaDocumento: (doc) => {
      dispatch(leadsActions.selecionaDocumento(doc))
    },
    selecionaLead: (lead, update) => {
      dispatch(leadsActions.selecionaLead(lead, update));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsComponent);
