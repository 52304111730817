import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import dotenv from 'dotenv'
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { configureStore, persistor } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import './index.css';
import App from './App';

dotenv.config()

ReactDOM.render(
  <Provider store={configureStore}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
, document.getElementById('root'));
registerServiceWorker();
