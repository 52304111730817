import styled from "styled-components";
// import { COR_FONTE_TITULO } from "../UI/Assets";

export const HomeWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const LeadsHomeWrapper = styled.div`
  flex: 11;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 0;
`;

export const LeadsLoadWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & span {
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: black;
  }
`;


export const LeadsFailWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & span {
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: red;
  }
`;