import React from "react";
import { NavbarWrapper } from "./Navbar.styled";
// import * as adminLogo from "./../../../assets/icons/logo.png";
import { INOVA_MIND_LOGO } from "./../../UI/Assets";
import { Dropdown, Icon } from 'semantic-ui-react';

/**
 * Barra de navegação do Home
 *
 * @props
 */
const NavbarComponent = (props) => {

  // Render para o triger do dropdown.
  const trigger = () => {
    let user = props.user;
    user = user === null ? "" : user;
    return (
      <span>
        <Icon name="user" /> {user}
      </span>
    );
  }

  return (
    <NavbarWrapper>
        <div className="nav-logo">
          <img src={INOVA_MIND_LOGO} width="100px" height="100px" alt="logo"/>
        </div>
        <div className="nav-menu">
         <span>Administrador</span>
        </div>
        <div className="nav-sair">
          <Dropdown trigger={trigger()} pointing='top right' icon="angle down" >
            <Dropdown.Menu>
              <Dropdown.Item key='settings' text='Configurações' icon='settings' />
              <Dropdown.Item key='sign-out' text='Sair' icon='sign out' onClick={props.navegaParaLogin} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </NavbarWrapper>
  );
}

export default NavbarComponent;