import React, {Component} from "react";

import { Button, Header, Icon, Modal, Input } from 'semantic-ui-react';
import { AdicionarLeadModalWrapper } from "./AdicionarLeadModal.styled";

import { connect } from 'react-redux';
import * as leadsActions from "../../../redux/leads/leads.actions";
import LeadHanlder from "../../../services/leadsHandler";


/**
 * Modal para adicionar um novo lead.
 *
 * @class AdicionarLeadModal
 * @extends {Component}
 */
class AdicionarLeadModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      novoLeadInput: "",
      erroExiste: false,
    };
    
  }

  // Fecha o modal.
  fechaModal = () => {
    if(this.props.leadsReducer.get("adicionarLeadSuccess"))
      this.props.adicionarFalseSuccessLead();
    this.setState({novoLeadInput: "", erroExiste: false}, () => {
      this.props.fechaModal();
    })
  }

  componentWillUnmount() {
    this.fechaModal();
  }

  // Entrada de dados do novo lead.
  novoLeadInputHandler = (valor) => {
    this.setState({novoLeadInput: valor, erroExiste: false});
  }

  // Adiciona Lead.
  adicionarLead = () => {
    if(this.state.novoLeadInput.length === 0)
      return;
    const leads = this.props.leadsReducer.get("leads");
    const novoLead = this.state.novoLeadInput;
    const existe = LeadHanlder.leadJaCadastrado(leads, novoLead) === false ? false : true;
    
    if(existe) {
      this.setState({erroExiste: true});
    }

    else {
      this.props.adicionarLead(novoLead);
    }
  }

  componentDidUpdate() {
    if(this.props.leadsReducer.get("adicionarLeadSuccess")) {
      this.fechaModal();
    }
  }

  render() {
    const adicionarLeadFail = this.props.leadsReducer.get("adicionarLeadFail");
    const adicionarLeadLoad = this.props.leadsReducer.get("adicionarLeadLoad");
    return(
      <Modal size="mini" open={this.props.showModal} closeIcon onClose={this.fechaModal} >
        <Header icon='file text' content='Adicionar Lead' />
        <Modal.Content>
          <AdicionarLeadModalWrapper erroExiste={this.state.erroExiste} erroServidor={adicionarLeadFail} >
            <Input label='Lead' placeholder='Nome do Lead' value={this.state.novoLeadInput}
            onChange={(e) => this.novoLeadInputHandler(e.target.value)} /* maxLength="29" */ />
            <p className="erro-existe">Lead já existente</p>
            <p className="erro-servidor">Não foi possível adicionar</p>
          </AdicionarLeadModalWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.adicionarLead} loading={adicionarLeadLoad} 
          disabled={adicionarLeadLoad}>
            <Icon name='checkmark' /> Adicionar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adicionarLead: (novoLead) => {
      dispatch(leadsActions.adicionarLead(novoLead))
    },
    adicionarFalseSuccessLead: () => {
      dispatch(leadsActions.adicionarFalseSuccessLead())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarLeadModal);