import React, {Component} from "react";
import { ListarLeadsWrapper, ListarLeadsTituloWrapper, ListarPesquisarLeadsWrapper, SelectCategoriaWrapper } from "./ListarLeads.styled";
import ListarItensLeadsComponent from "./ListarItensLeads";

import { connect } from 'react-redux';
import * as leadsActions from "./../../../redux/leads/leads.actions";
import BotaoInovaMind from "./../../UI/BotaoInovaMind";
import AdicionarLeadModal from "../../modais/adicionarLead/AdicionarLeadModal";
import { Input, Icon, Button, Popup, Select } from "semantic-ui-react";
import { Subject } from "rxjs";
import LeadsHanlder from "../../../services/leadsHandler";
import ArquivarLeadModal from "./../../modais/arquivarLead/ArquivarLead";

/**
 * Lista os leads da aplicação
 *
 * @class ListarLeadsComponent
 * @extends {Component}
 */
class ListarLeadsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      leadSelecionado: this.props.leadsReducer.get("leadSelecionado"),
      modalAdicionarLead: false,
      pesquisaInput: "",
      showSearch: false,
      categoria: "Processado_Pendente",
      showLeadArquivar: false,
    };

    this.pesquisaLeadRxjs = new Subject() // Para a pesquisa.
    this.subscriptionPesquisa = null; // Recebe as subscribe dos obersavables.

    this.leadArquivarSelecionado = null;
  }

  // Abre o modal de arquivação de lead.
  showLeadArquivarHandler = (leadSelecionado) => {
    this.leadArquivarSelecionado = leadSelecionado;
    this.setState({showLeadArquivar: true});
  }

  // Fecha o modal de arquivação de lead.
  fechaModalLeadArquivar = () => {
    this.leadArquivarSelecionado = null;
    this.setState({showLeadArquivar: false});
  }

  // Após a inicialização do dom.
  componentDidMount(){
    // Espera 1s após o usuário digitar e aí dispara o evento com o valor digitado.
    this.subscriptionPesquisa = this.pesquisaLeadRxjs
      .debounceTime(100)
      .distinctUntilChanged()
      .subscribe(this.getObsPage());
  }

  // Funções para o Observable do input de pesquisa.
  getObsPage() {
    let obs = {
      next: (data) =>{
        this.setState({pesquisaInput: data});
      },
    }
    return obs;
  }

  pesquisaLeadHandler = (valor) => {
    this.pesquisaLeadRxjs.next(valor);
  }


  // Abre o modal de adicionar o lead.
  showModalAdcionarLead = () => {
    this.setState({modalAdicionarLead: true});
  }

  // Fecha o modal de adicionar lead.
  fechaModalAdicionarLead = () => {
    this.setState({modalAdicionarLead: false});
  }

  // Seleciona um novo lead
  selecionaNovoLead = (lead) => {
    this.props.selecionaLead(lead, false);
    // this.setState({leadSelecionado: leadSelecionado});
  }

  // Ativa o campo de pesquisa
  showSearchFormHandler = () => {
    if(this.state.showSearch) {
      this.setState({showSearch: !this.state.showSearch, pesquisaInput: ""});
    }
    else {
      this.setState({showSearch: !this.state.showSearch});
    }
  }

  // Retorna as opções do select das categorias
  categoriasOps = () => {
    return [
      {key: "processed+pending", value: "Processado_Pendente", text:(
          <div>
            <Icon name="tags" color="yellow" />
            <span>Processados + Pendentes</span>
          </div>
        ),
      }, 
      {key: "processed", value: "Processado", text:(
          <div>
            <Icon name="check circle outline" color="green" />
            <span>Processados</span>
          </div>
        ),
      }, 
      {key: "pending", value: "Pendente", text:(
          <div>
            <Icon name="wait" color="orange" />
            <span>Pendentes</span>
        </div>
        ),
      }, 
      {key: "archive", value: "Arquivado", text:(
          <div>
            <Icon name="folder" color="brown" />
            <span>Arquivados</span>
          </div>
        ),
      },
      {key: "all", value: "Todos", text:(
        <div>
          <Icon name="archive" color="blue" />
          <span>Todos</span>
        </div>
      ),
    }, 
    ];
  }

  // Seleciona a categoria
  selecionaCategoria = (e, { value }) => {
    if(value !== this.state.categoria) {
      this.setState({categoria: value}, () => {
        // this.props.limpaLead();
      });
    }
  }

  render() {
    const leads = this.props.leadsReducer.get("leads");
    const leadSel = this.props.leadsReducer.get("leadSelecionado")
    const leadSelecionado = leadSel === null ? leadSel : leadSel.name;

    let listaLeads = LeadsHanlder.filtraListaLeads(leads, this.state.pesquisaInput);

    // Se não há pesquisa, então lista por categoria.
    if(this.state.pesquisaInput.length === 0) {
      if(this.state.categoria === "Processado") {
        listaLeads = leads.filter(item => {
          if(item.processed && item.archived === false) {
            return item;
          }
          return null;
        });
      }
      else if(this.state.categoria === "Pendente") {
        listaLeads = leads.filter(item => {
          if(!item.processed && item.archived === false) {
            return item;
          }
          return null;
        });
      }
      else if(this.state.categoria === "Arquivado") {
        listaLeads = leads.filter(item => {
          if(item.archived) {
            return item;
          }
          return null;
        });
      }
      else if(this.state.categoria === "Processado_Pendente") {
        listaLeads = leads.filter(item => {
          if(!item.archived) {
            return item;
          }
          return null;
        });
      }
      else {
        listaLeads = leads;
      }
    } 
    
    return (
      <ListarLeadsWrapper className="listar-leads-wrapper">
       
        <ListarLeadsTituloWrapper className="listar-leads-titulo">
          <p style={{flex: 1}}></p>
          <span>Leads</span>
          <div style={{flex: 1}}>
            <Popup
              trigger={
                <Button icon circular compact onClick={this.showSearchFormHandler} >
                  <Icon name="search" color="black" />
                </Button>
              }
              content='Pesquisar Lead'
              position='top center'
              inverted
            />
          </div>
        </ListarLeadsTituloWrapper>

        <React.Fragment>
          {
            this.state.showSearch === false 
            ?
            <SelectCategoriaWrapper>
              <Select placeholder='Seleciona' options={this.categoriasOps()} 
              value={this.state.categoria} onChange={this.selecionaCategoria} fluid/>
            </SelectCategoriaWrapper>
            :
            <ListarPesquisarLeadsWrapper>
              <Input placeholder="Pesquisar Lead"
              icon={<Icon name='close' link onClick={this.showSearchFormHandler} /> }
              onChange={(e) => this.pesquisaLeadHandler(e.target.value)} />
            </ListarPesquisarLeadsWrapper>
          }
        </React.Fragment>

        <ListarItensLeadsComponent leads={listaLeads} 
          selecionado={leadSelecionado}
          selecionaNovoLead={this.selecionaNovoLead}
          arquivarLead={this.showLeadArquivarHandler}
          category={this.state.categoria}
        />

        <div style={{textAlign: "center", margin: " 0 0.5rem 0.5rem 0.5rem"}}>
          <BotaoInovaMind size="mini" onClick={this.showModalAdcionarLead} >Adicionar Lead</BotaoInovaMind>
        </div>

        <AdicionarLeadModal 
          showModal={this.state.modalAdicionarLead}
          fechaModal={this.fechaModalAdicionarLead} 
        />

        <ArquivarLeadModal 
          showModal={this.state.showLeadArquivar}
          fechaModal={this.fechaModalLeadArquivar}
          lead={this.leadArquivarSelecionado}
        />

      </ListarLeadsWrapper>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    leadsReducer: state.leadsReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selecionaLead: (lead, update) => {
      dispatch(leadsActions.selecionaLead(lead, update))
    },
    limpaLead: () => {
      dispatch(leadsActions.limpaLead())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListarLeadsComponent);