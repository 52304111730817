import styled from "styled-components";
import { INOVA_MIND_COLOR, INOVA_MIND_COLOR_HOVER } from "./../../components/UI/Assets";


export const LoginWrapper = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(360deg, #fdfbfb 0%, #f5f5f5 100%);


`;

export const LogoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & *{
    width: 100%;
  }
`;

export const EntradaDadosWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 2px 2px 2px 2px gray;

  width: 450px;
  padding: 2rem 0 3.5rem 0;

  & .entrada-acoes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    & * {
      width: 100%;
    }
  }

  & *:not(.logo-wrapper) {
    margin-top: 0.6rem;
  }

  & .botao-entrar {
    margin-top: 1rem;
  }

  & .botao-entrar > button, button:focus {
    background-color: ${INOVA_MIND_COLOR} !important;
    color: white !important;
  }

  & .botao-entrar button:hover{
    background-color: ${INOVA_MIND_COLOR_HOVER} !important;
    color: white !important;
  }
`;
