import React from "react";

import { Input } from 'semantic-ui-react';


const FormCpfComponent = (props) => {

  return(
    <div className="entrada-cpf">
      <Input label="CPF" placeholder={`Número do CPF`} value={props.value} fluid maxLength="14"
          onChange={(e) => props.entrada(e.target.value)} type="text" />
      <Input label="RG" placeholder="Número do RG" value={props.valueRg} fluid maxLength="12"
          onChange={(e) => props.entradaRg(e.target.value)} type="text" />
      <div style={{textAlign: "center"}}>
        <span style={{display: props.validate ? "none" : "block", color: "red"}}>CPF inválido</span>
      </div>
    </div>
  );
}

export default FormCpfComponent;