import React from 'react';
import styled from "styled-components";
import { Button } from 'semantic-ui-react';
import { INOVA_MIND_COLOR, INOVA_MIND_COLOR_HOVER } from './Assets';

const BotaoInovaMindWrapper = styled.div`
  & > button, button:focus {
    background-color: ${INOVA_MIND_COLOR} !important;
    color: white !important;
  }

  & button:hover{
    background-color: ${INOVA_MIND_COLOR_HOVER} !important;
    color: white !important;
  }
`;

const BotaoInovaMind = (props) => {
  return(
    <BotaoInovaMindWrapper className="botao-inovamind">
      <Button {...props}>{props.children}</Button>
    </BotaoInovaMindWrapper>
  );
}

export default BotaoInovaMind;