import axios from 'axios';

const SERVER = process.env.REACT_APP_API_FILES_DOWNLOADER;
const AUTH = process.env.REACT_APP_API_FILES_DOWNLOADER_KEY;

const api = axios.create({
  baseURL: SERVER,
});

api.interceptors.request.use(async (config) => {
  const newConfig = config;

  newConfig.headers.Authorization = AUTH;

  return newConfig;
});

const reqS3PresignerUrl = fileUrl => api.get(`${SERVER}/s3/presigner/url?file_url=${fileUrl}`);

export default api;
export { reqS3PresignerUrl };
