import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AuthScreen from '../screens/auth/Auth.screen';
import HomeScreen from '../screens/home/Home.screen';

// Declaration of routes for at sub-routes of app.
const Routes = () => (
  <main>
    <Switch>
      <Route exact path='/login' component={AuthScreen}/>
      <Route exact path='/' component={AuthScreen} />
      <Route exact path='/home' component={HomeScreen}/>
      <Route exact path='/*' component={HomeScreen}/>
    </Switch>
  </main>
)

export default Routes
