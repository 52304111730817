import styled from "styled-components";
import { BORDA_COR, COR_FONTE_TITULO } from "../../UI/Assets";

export const ListarLeadsWrapper = styled.div`
  border: 1px solid ${BORDA_COR};
  border-top-width: 0;
  width: 300px;
  padding: 0.5rem 0.2rem 0 0;
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  /* min-height: 0; */

  /* overflow-y: auto; */

`;

export const ListarPesquisarLeadsWrapper = styled.div`
  margin-top: 1rem;
  padding: 0.3rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: stretch;

  & * {
    width: 100%;
  }
`;

export const ListarLeadsTituloWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span {
    flex: 3;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: ${COR_FONTE_TITULO};
  }
`;

export const SelectCategoriaWrapper = styled.div`
  margin-top: 1rem;
  padding: 0.3rem 0.5rem;
`;
