import "rxjs-compat";
import * as leadsActions from "./leads.actions";
import ApiService from "../../services/apiService";
import LeadsHanlder from "../../services/leadsHandler";

export const leadsEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.REQUEST_LEADS)
  .switchMap(async (action) => { 

    const token = store.value.authReducer.get("token");
    const leads = await ApiService.getLeads(token);

    // console.log(leads);
    if(leads !== null) {
      return leadsActions.successLeads(leads);
    }
    
    return leadsActions.failLeads();
  });
}


/* ************************************** SELECIONAR LEAD ************************************** */

export const selecionaLeadsEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.SELECIONA_LEAD)
  .switchMap(async (action) => { 

    const { payload } = action;

    const update = payload.update;

    const token = store.value.authReducer.get("token");
    // const leads = store.value.leadsReducer.get("leads");

    const leadId = payload.lead;

    // Pega todos os cpf's.
    const cpfs = await ApiService.getLeadCpf(token, leadId);

    if(cpfs === null) {
      return leadsActions.selecionaFailLead();
    }

    // Pega todos os cnpj's.
    const cnpjs = await ApiService.getLeadCnpj(token, leadId);
    
    if(cnpjs === null) {
      return leadsActions.selecionaFailLead();
    }

    // Pega todos os ccm's.
    const ccms = await ApiService.getLeadCcm(token, leadId);

    if(ccms === null) {
      return leadsActions.selecionaFailLead();
    }

    // console.log(lead);


    // console.log(leadSelecionado, cnpjs);

    let docAtual = null;
    if(update) {
      docAtual = store.value.leadsReducer.get("selecionaDocumento");

      // console.log(docAtual);

      if(docAtual !== null) {

        if("cpf" in docAtual) {
          cpfs.forEach(item => {
            if(item.cpf === docAtual.numero) {
              docAtual = {
                ...item,
                tipo: "cpf"
              }
            }
          });
        }
  
        else if("cnpj" in docAtual) {
          cnpjs.forEach(item => {
            if(item.cnpj === docAtual.numero) {
              docAtual = {
                ...item,
                tipo: "cnpj"
              }
            }
          });
        }
  
        else{
          ccms.forEach(item => {
            if(item.property === docAtual.numero) {
              docAtual = {
                ...item,
                tipo: "ccm"
              }
            }
          });
        }

      }
    }

    const novosLeads = await ApiService.getLeads(token);

    if(novosLeads === null) {
      return leadsActions.deletarFailDocumento();
    }
    
    const lead = LeadsHanlder.selecionaLead(novosLeads, leadId);
    
    const leadSelecionado = {
      ...lead,
      cpf: {
        processed: LeadsHanlder.verificaStatus([...cpfs]),
        lista: [...cpfs]
      },
      cnpj: {
        processed: LeadsHanlder.verificaStatus([...cnpjs]),
        lista: [...cnpjs]
      },
      ccm: {
        processed: LeadsHanlder.verificaStatus([...ccms]),
        lista: [...ccms]
      }
    }

     /*  console.log(docAtual);

      console.log(leadSelecionado);

      const leads = store.value.leadsReducer.get("leads");

      console.log(leads); */

    return leadsActions.selecionaSuccessLead(leadSelecionado, docAtual, novosLeads);
    
  });
}


/* ************************************** Adicionar LEAD ************************************** */

export const adicionaLeadsEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.ADICIONAR_LEAD)
  .switchMap(async (action) => { 

    const { payload } = action;
    const token = store.value.authReducer.get("token");

    let lead = await ApiService.postLead(token, payload.novoLead);
    if(lead === null) {
      return leadsActions.adicionarFailLead();
    }

    const novoLeadSelecionado = {
      ...lead,
      cpf: {
        processed: false,
        lista: [],
      },
      cnpj: {
        processed: false,
        lista: [],
      },
      ccm: {
        processed: false,
        lista: [],
      },
    }

    const novosLeads = await ApiService.getLeads(token);

    if(novosLeads === null) {
      return leadsActions.adicionarFailLead();
    }
      
    return leadsActions.adicionarSuccessLead(novosLeads, novoLeadSelecionado);

  });
}


/* ************************************** DELETA LEAD ************************************** */

export const deletarLeadsEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.DELETAR_LEAD)
  .switchMap(async (action) => { 

    const { payload } = action;
    const token = store.value.authReducer.get("token");

    let lead = await ApiService.removeLead(token, payload.lead);
    if(lead === true) {
    const novosLeads = await ApiService.getLeads(token);
      return leadsActions.deletarSuccessLead(novosLeads);
    }
    return leadsActions.deletarFailLead();
  });
}


/* ************************************** DELETAR DOCUMENTO ************************************** */

export const deletarDocumentosEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.DELETAR_DOCUMENTO)
  .switchMap(async (action) => { 

    const { payload } = action;
    const token = store.value.authReducer.get("token");

    // Pega o token, remove o documento no servidor, em caso de ok, dá um get la lista de documentos
    // atráves do tipo dele. Pega o lead selecionado e atualiza a lista de documento referido no texto.

    let lead = await ApiService.removeDocumento(token, payload.lead, payload.tipo, payload.documento);
    if(lead === true) {
      const leadSelecionado = store.value.leadsReducer.get("leadSelecionado");
      const leadId = payload.lead;
      const tipo = payload.tipo;

      let novosDocumentos = [];

      if(tipo === "cpf") {
        // Pega todos os cpf's.
        novosDocumentos = await ApiService.getLeadCpf(token, leadId);

        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }
      }

      // Pega todos os cnpj's.
      if(tipo === "cnpj") {
        // Pega todos os cnpjs's.
        novosDocumentos = await ApiService.getLeadCnpj(token, leadId);

        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }
      }

      // Pega todos os ccm's.
      if(tipo === "ccm") {
        // Pega todos os cmm's.
        novosDocumentos = await ApiService.getLeadCcm(token, leadId);

        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }
      }

      const novosLeads = await ApiService.getLeads(token);

      if(novosLeads === null) {
        return leadsActions.deletarFailDocumento();
      }

      // Atualiza o status dos leads.
      novosLeads.forEach(item => {
        if(item.name === leadSelecionado.name) {
          leadSelecionado.processed = item.processed;
        }
      })

      // Atualiza o lead selecionado.
      const novoLeadSelecionado = {
        ...leadSelecionado,
        [tipo]: {
          processed: LeadsHanlder.verificaStatus([...novosDocumentos]),
          lista: [...novosDocumentos]
        }
      }

      // console.log(leadSelecionado, novoLeadSelecionado);

      return leadsActions.deletarSuccessDocumento(novoLeadSelecionado, novosLeads);
    }
    return leadsActions.deletarFailDocumento();
  });
}


/* ************************************** ADICIONAR DOCUMENTO ************************************** */

export const adicionarDocumentosEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.ADICIONAR_DOCUMENTO)
  .switchMap(async (action) => { 

    const { payload } = action;
    const token = store.value.authReducer.get("token");
    const lead = payload.lead;
    const tipo = payload.tipo;
    let documento = payload.documento;
    const serasaControl = payload.serasa;

    let doc = await ApiService.adicionaDocumento(token, lead, tipo, documento, serasaControl);
    if(doc !== null) {
      const leadSelecionado = store.value.leadsReducer.get("leadSelecionado");
      const leadId = lead;

      let novosDocumentos = [];
      let novoDocumentoSelecionado = {};

      // console.log(doc);

      if(tipo === "cpf") {
        // Pega todos os cpf's.
        novosDocumentos = await ApiService.getLeadCpf(token, leadId);
        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }

        // Pega as infos do novo documento.
        novosDocumentos.forEach(elemento => {
          if(elemento.cpf === documento.cpf)
            doc = elemento
        });
        novoDocumentoSelecionado = {
          ...doc,
          numero: doc.cpf,
          rg: doc.rg,
          tipo: "cpf"
        }
      }

      // Pega todos os cnpj's.
      if(tipo === "cnpj") {
        // Pega todos os cnpjs's.
        novosDocumentos = await ApiService.getLeadCnpj(token, leadId);
        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }

        novosDocumentos.forEach(elemento => {
          if(elemento.cnpj === documento.cnpj)
            doc = elemento
        });

        novoDocumentoSelecionado = {
          ...doc,
          numero: doc.cnpj,
          tipo: "cnpj"
        }
      }

      // Pega todos os ccm's.
      if(tipo === "ccm") {
        // Pega todos os cmm's.
        novosDocumentos = await ApiService.getLeadCcm(token, leadId);
        if(novosDocumentos === null) {
          return leadsActions.deletarFailDocumento();
        }
        novosDocumentos.forEach(elemento => {
          if(elemento.property === documento.ccm)
            doc = elemento
        });
        novoDocumentoSelecionado = {
          ...doc,
          numero: doc.property,
          tipo: "ccm"
        }
      }

      // Atualiza o status dos leads.
      const novosLeads = await ApiService.getLeads(token);

      if(novosLeads === null) {
        return leadsActions.adicionarFailDocumento();
      }

      novosLeads.forEach(item => {
        if(item.name === leadSelecionado.name) {
          leadSelecionado.processed = item.processed;
        }
      })  // Atualiza o status dos leads.


      // Atualiza o lead corrente.
      const novoLeadSelecionado = {
        ...leadSelecionado,
        [tipo]: {
          processed: LeadsHanlder.verificaStatus([...novosDocumentos]),
          lista: [...novosDocumentos]
        }
      }

      // console.log(leadSelecionado, novoLeadSelecionado);

      return leadsActions.adicionarSuccessDocumento(novoLeadSelecionado, novoDocumentoSelecionado, novosLeads);
    }
    return leadsActions.adicionarFailDocumento();
  });
}


/* ************************************** EDITAR LEAD ************************************** */

export const editaLeadEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.EDITAR_LEAD)
  .switchMap(async (action) => { 

    const { payload } = action;

    const novoNome = payload.novoNome;
    const token = store.value.authReducer.get("token");
    const leadSelecionado = store.value.leadsReducer.get("leadSelecionado");
    const leadId = leadSelecionado.lead_id;
    const novoLead = await ApiService.editaLead(token, leadId, novoNome);
    
    if(novoLead !== null) {

      // Atualizar a lista de leads.
      let leads = store.value.leadsReducer.get("leads");

      leads = leads.map(itemLead => {
        if(itemLead.lead_id === novoLead.lead_id) {
          itemLead = novoLead;
        }
        return itemLead;
      });

      // Atualiza lead selecionado.
      const novoLeadSelecionado = {
        ...leadSelecionado,
        name: novoLead.name
      }

      // console.log(leads, novoLeadSelecionado);

      return leadsActions.editaLeadSuccess(leads, novoLeadSelecionado);
    }
    return leadsActions.editaLeadFail();
  });
}


/* ************************************** ARQUIVAR LEAD ************************************** */

export const arquivarLeadEpics = (action$, store) => {
  return action$
  .ofType(leadsActions.ARQUIVAR_LEAD)
  .switchMap(async (action) => { 

    const { payload } = action;

    const lead = payload.lead;
    const token = store.value.authReducer.get("token");
    const leadSelecionado = store.value.leadsReducer.get("leadSelecionado");

    console.log(lead);
    
    const novoLead = await ApiService.arquivaLead(token, lead.lead_id, lead.archived);

    if(novoLead !== null) {

      // Atualizar a lista de leads.
      const leads = await ApiService.getLeads(token);

      let novoLeadSelecionado = null;

      // Atualiza lead selecionado.
      if(leadSelecionado !== null) {
        let novoLead = null;
        leads.forEach(itemLead => {
          if(itemLead.lead_id === leadSelecionado.lead_id) {
            novoLead = itemLead;
          }
        });
        novoLeadSelecionado = {
          ...leadSelecionado,
          processed: novoLead.processed
        }
      }

      // console.log(leads, novoLeadSelecionado);
      console.log(novoLeadSelecionado);

      return leadsActions.arquivarLeadSuccess(leads, novoLeadSelecionado);
    }
    return leadsActions.arquivarLeadFail();
  });
}
