
export const REQUEST_LEADS = "REQUEST_LEADS";
export const SUCCESS_LEADS = "SUCCESS_LEADS";
export const FAIL_LEADS = "FAIL_LEADS";

export const SELECIONA_LEAD = "SELECIONA_LEAD";
export const SELECIONA_SUCCESS_LEAD = "SELECIONA_SUCCESS_LEAD";
export const SELECIONA_FAIL_LEAD = "SELECIONA_FAIL_LEAD";
export const LIMPA_LEAD = "LIMPA_LEAD";

export const ADICIONAR_LEAD = "ADICIONAR_LEAD";
export const ADICIONAR_SUCCESS_LEAD = "ADICIONAR_SUCCESS_LEAD";
export const ADICIONAR_FAIL_LEAD = "ADICIONAR_FAIL_LEAD";
export const ADICIONAR_FALSE_SUCCESS_LEAD = "ADICIONAR_FALSE_SUCCESS_LEAD";

export const DELETAR_LEAD = "DELETAR_LEAD";
export const DELETAR_SUCCESS_LEAD = "DELETAR_SUCCESS_LEAD";
export const DELETAR_FAIL_LEAD = "DELETAR_FAIL_LEAD";

export const SELECIONA_DOCUMENTO = "SELECIONA_DOCUMENTO";

export const DELETAR_DOCUMENTO = "DELETAR_DOCUMENTO";
export const DELETAR_SUCCESS_DOCUMENTO = "DELETAR_SUCCESS_DOCUMENTO";
export const DELETAR_FAIL_DOCUMENTO = "DELETAR_FAIL_DOCUMENTO";

export const ADICIONAR_DOCUMENTO = "ADICIONAR_DOCUMENTO";
export const ADICIONAR_SUCCESS_DOCUMENTO = "ADICIONAR_SUCCESS_DOCUMENTO";
export const ADICIONAR_FAIL_DOCUMENTO = "ADICIONAR_FAIL_DOCUMENTO";
export const ADICIONAR_FALSE_SUCCESS_DOCUMENTO = "ADICIONAR_FALSE_SUCCESS_DOCUMENTO";

export const EDITAR_LEAD = "EDITAR_LEAD";
export const EDITAR_LEAD_SUCCESS = "EDITAR_LEAD_SUCCESS";
export const EDITAR_LEAD_FAIL = "EDITAR_LEAD_FAIL";
export const EDITAR_LEAD_FALSE_SUCCESS = "EDITAR_LEAD_FALSE_SUCCESS";

export const ARQUIVAR_LEAD = "ARQUIVAR_LEAD";
export const ARQUIVAR_LEAD_SUCCESS = "ARQUIVAR_LEAD_SUCCESS";
export const ARQUIVAR_LEAD_FAIL = "ARQUIVAR_LEAD_FAIL";
export const ARQUIVAR_LEAD_FALSE_SUCCESS = "ARQUIVAR_LEAD_FALSE_SUCCESS";

export const ZERA_REDUCER = "ZERA_REDUCER";


export const requestLeads = () => {
  return {
    type: REQUEST_LEADS,
  }
}

export const successLeads = (leads) => {
  return {
    type: SUCCESS_LEADS,
    payload: {
      leads: leads
    }
  }
}

export const failLeads = () => {
  return {
    type: FAIL_LEADS,
  }
}


/* ************************************** SELECIONAR LEAD ************************************** */

export const selecionaLead = (lead, update) => {
  return {
    type: SELECIONA_LEAD,
    payload: {
      lead: lead,
      update: update
    }
  }
}

export const selecionaSuccessLead = (lead, update, leads) => {
  return {
    type: SELECIONA_SUCCESS_LEAD,
    payload: {
      lead: lead,
      update: update,
      leads: leads
    }
  }
}

export const selecionaFailLead = () => {
  return {
    type: SELECIONA_FAIL_LEAD,
  }
}

export const limpaLead = () => {
  return {
    type: LIMPA_LEAD,
  }
}


/* ************************************** Adicionar LEAD ************************************** */

export const adicionarLead = (novoLead) => {
  return {
    type: ADICIONAR_LEAD,
    payload: {
      novoLead: novoLead,
    }
  }
}

export const adicionarSuccessLead = (leads, leadSelecionado) => {
  return {
    type: ADICIONAR_SUCCESS_LEAD,
    payload: {
      leads: leads,
      leadSelecionado: leadSelecionado
    }
  }
}

export const adicionarFailLead = () => {
  return {
    type: ADICIONAR_FAIL_LEAD,
  }
}

export const adicionarFalseSuccessLead = () => {
  return {
    type: ADICIONAR_FALSE_SUCCESS_LEAD
  }
}


/* ************************************** Deletar LEAD ************************************** */

export const deletarLead = (lead) => {
  return {
    type: DELETAR_LEAD,
    payload: {
      lead: lead,
    }
  }
}

export const deletarSuccessLead = (leads) => {
  return {
    type: DELETAR_SUCCESS_LEAD,
    payload: {
      leads: leads,
    }
  }
}

export const deletarFailLead = () => {
  return {
    type: DELETAR_FAIL_LEAD,
  }
}


/* ************************************** SELECIONA DOCUMENTO ************************************** */

export const selecionaDocumento = (documento) => {
  return {
    type: SELECIONA_DOCUMENTO,
    payload: {
      documento: documento,
    }
  }
}


/* ************************************** DELETAR DOCUMENTO ************************************** */

export const deletarDocumento = (lead, tipo, documento) => {
  return {
    type: DELETAR_DOCUMENTO,
    payload: {
      lead: lead,
      tipo: tipo,
      documento: documento,
    }
  }
}

export const deletarSuccessDocumento = (novoLeadSelecionado, novosLeads) => {
  return {
    type: DELETAR_SUCCESS_DOCUMENTO,
    payload: {
      novoLeadSelecionado: novoLeadSelecionado,
      leads: novosLeads
    }
  }
}

export const deletarFailDocumento = () => {
  return {
    type: DELETAR_FAIL_DOCUMENTO,
  }
}


/* ************************************** ADICIONAR DOCUMENTO ************************************** */

export const adicionarDocumento = (lead, tipo, documento, serasa) => {
  return {
    type: ADICIONAR_DOCUMENTO,
    payload: {
      lead: lead,
      tipo: tipo,
      documento: documento,
      serasa: serasa
    }
  }
}

export const adicionarSuccessDocumento = (novoLeadSelecionado, novoDocumento, novosLeads) => {
  return {
    type: ADICIONAR_SUCCESS_DOCUMENTO,
    payload: {
      novoLeadSelecionado: novoLeadSelecionado,
      novoDocumento: novoDocumento,
      leads: novosLeads
    }
  }
}

export const adicionarFailDocumento = () => {
  return {
    type: ADICIONAR_FAIL_DOCUMENTO,
  }
}

export const adicionarFalseSuccessDocumento = () => {
  return {
    type: ADICIONAR_FALSE_SUCCESS_DOCUMENTO
  }
}


/* ************************************** ZERA REDUCER ************************************** */

export const zeraReducer = () => {
  return {
    type: ZERA_REDUCER,
  }
}



/* ************************************** EDITAR LEAD ************************************** */

export const editaLead = (novoNome) => {
  return {
    type: EDITAR_LEAD,
    payload: {
      novoNome: novoNome,
    }
  }
}

export const editaLeadSuccess = (leads, leadSelecionado) => {
  return {
    type: EDITAR_LEAD_SUCCESS,
    payload: {
      leads: leads,
      leadSelecionado: leadSelecionado
    }
  }
}

export const editaLeadFail = () => {
  return {
    type: EDITAR_LEAD_FAIL,
  }
}

export const editaLeadFalseSuccess = () => {
  return {
    type: EDITAR_LEAD_FALSE_SUCCESS,
  }
}


/* ************************************** ARQUIVAR LEAD ************************************** */

export const arquivarLead = (lead) => {
  return {
    type: ARQUIVAR_LEAD,
    payload: {
      lead: lead,
    }
  }
}

export const arquivarLeadSuccess = (leads, leadSelecionado) => {
  return {
    type: ARQUIVAR_LEAD_SUCCESS,
    payload: {
      leads: leads,
      leadSelecionado: leadSelecionado
    }
  }
}

export const arquivarLeadFail = () => {
  return {
    type: ARQUIVAR_LEAD_FAIL,
  }
}

export const arquivarLeadFalseSuccess = () => {
  return {
    type: ARQUIVAR_LEAD_FALSE_SUCCESS,
  }
}

