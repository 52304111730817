import React, { Component } from 'react';
import { Modal, Icon } from 'semantic-ui-react';

class CarregandoLeadModalComponent extends Component {
  
  render() {
    // const loadingLead = this.props.leadsReducer.get("loadSelecionaLead");
    return (
      <Modal size="mini" open={true}>
        <Modal.Header>Carregando o lead...</Modal.Header>
        <Modal.Content>
          <div style={{width: "100%", textAlign: "center"}}>
            <Icon loading name='spinner' size="huge" />
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

export default CarregandoLeadModalComponent;