import "rxjs-compat";
import * as authActions from "./auth.actions";
import ApiService from "./../../services/apiService";

export const authEpics = (action$, store) => {
  return action$
  .ofType(authActions.VALIDA_USUARIO)
  .switchMap(async (action) => { 
    const { payload } = action;

    const validaUsuario = await ApiService.validaUsuario(payload.usuario, payload.senha);
    // console.log(validaUsuario);

    if(validaUsuario !== null) {
      return authActions.insereUsuario(payload.usuario, payload.senha, validaUsuario);
    }
    
    return authActions.falhaValidacao();
  });
}
