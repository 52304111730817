import * as cpfValidator from '@fnando/cpf'
import * as cnpjValidator from '@fnando/cnpj'

class Validators {

  // Valida um cpf.
  static validaCPF = (cpf) => {
    return cpfValidator.isValid(cpf, true)
  }

  // Adiciona mascara ao cpf
  static mascaraCpf = (cpf) => {
    cpf = cpf.replace(/\D/g,"");
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    return cpf;
  }

  // Adiciona mascara do rg
  static mascaraRg = (rg) => {
    rg = rg.replace(/\D/g,"");
    rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/,"$1.$2.$3-$4");
    return rg;
  }

  // Remove as mascara para enviar.
  static removeMascara = (valor) => {
    return valor.replace(/(\.|\/|-)/g,"");
  }


  // Valida email.
  static validaEmail = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return true;
    return false;
  }

  static validaCnpj = (cnpj) => {
    return cnpjValidator.isValid(cnpj, true)
  }

  // Adiciona mascara ao cnpj
  static mascaraCnpj = (cnpj) => {
    cnpj = cnpj.replace(/\D/g,"");
    cnpj = cnpj.replace(/(\d{2})(\d)/,"$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/,"$1.$2");
    cnpj = cnpj.replace(/(\d{3})(\d)/,"$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/,"$1-$2");
    return cnpj;
  }


  // Valida o registro imobiliário.
  static validaRegistroImobiliario = (imo) => {
    if (/^\d{11}$/.test(imo))
      return true;
    return false;
  }

  // Adiciona mascara ao rim.
  static mascaraCcm = (ccm) => {
    ccm = ccm.replace(/\D/g,"");
    ccm = ccm.replace(/(\d{3})(\d)/,"$1.$2");
    ccm = ccm.replace(/(\d{3})(\d)/,"$1.$2");
    ccm = ccm.replace(/(\d{4})(\d{1,2})$/,"$1-$2");
    return ccm;

    // xxx.xxx.xxxx-x
  }


}

export default Validators;
