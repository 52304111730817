import React from 'react';
import styled from "styled-components";
import { List, Icon, Label, Popup } from 'semantic-ui-react';

import { reqS3PresignerUrl } from '../../../../services/apiFilesDownloader';

const ListaInfoDadosDocumentosComponent = (props) => {
  const s3PresignerUrl = async (doc_url) => {
    try {
      const response = await reqS3PresignerUrl(doc_url);

      window.open(response.data.url, '_blank');
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log(err.message);
      }
    }
  }

  return (
    <ListaInfoDadosDocWrapper>
      <List divided verticalAlign='middle'>
        {
          props.lista.filter((el) => el.show === true).map(item => {

            let cor = "";
            let icone = "";

            if(item.has_msg) {
              cor = "orange";
              icone = "file text outline";
            }
            else {
               // Verifica se o item foi processado, se sim, pinta de verde, caso contrário, laranja.
              if(item.processed === false) {
                cor = "orange";
                icone = "file pdf outline";
              }
              else {
                if(item.consists) {
                  cor = "red";
                  icone = "file excel outline";
                }
                else {
                  cor = "green";
                  icone = "file pdf outline";
                }
              }
            }

            // console.log(item.has_msg);

            return(
                <List.Item
                  style={{marginBottom: "1rem", paddingTop: "1rem"}}
                  key={item.title}
                >
                  <Icon name={icone} size="big" color={cor}
                  style={{opacity: item.processed ? null : '0.4'}}  />
                  <List.Content  >
                    <List.Header style={{opacity: item.processed ? null : '0.4'}}><span>{item.title}</span></List.Header>
                    <span style={{opacity: item.processed ? null : '0.4'}}>{item.result}</span>
                    {
                      item.processed ? null :
                      <Popup
                        trigger={
                          <Label color="orange" horizontal size="medium"
                          style={{opacity: '0.7', marginTop: "0.4rem"}} >
                            <Icon name="wait" /> {item.response_time === null ? "----" : item.response_time}
                          </Label>
                        }
                        content='Tempo Estimado'
                        position='top center'
                        inverted
                      />
                    }
                  </List.Content>
                  { !!item.document_url
                    ? (
                      <Icon>
                        <Icon
                            name="download"
                            size="big"
                            color="grey"
                            onClick={() => {
                              s3PresignerUrl(item.document_url);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                      </Icon>
                    ) : null
                  }
               </List.Item>
            );
          })
        }
    </List>
    </ListaInfoDadosDocWrapper>
  );
}

export default ListaInfoDadosDocumentosComponent;

export const ListaInfoDadosDocWrapper = styled.div`
  flex: 1;
`;
