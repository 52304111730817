import { Map } from 'immutable';

import { VALIDA_USUARIO, INSERE_USUARIO, REMOVE_USUARIO, 
FALHA_VALIDACAO } from "./auth.actions";

let authInitial = Map({
  usuario: null,
  senha: null,
  token: null,
  load: false,
  fail: false,
  totalMax: 15,
});

const authReducer = (state = authInitial, action) => {
  switch (action.type) {

    case VALIDA_USUARIO:
      state = state.set("load", true).set("fail", false);
    break;

    case INSERE_USUARIO:
      state = state.set("usuario", action.payload.usuario).set("senha", action.payload.senha)
      .set("load", false).set("fail", false).set("token", action.payload.token);
    break;

    case REMOVE_USUARIO:
      state = state.set("usuario", null).set("senha", null)
      .set("load", false).set("fail", false).set("token", null);
    break;

   case FALHA_VALIDACAO:
      state = state.set("load", false).set("fail", true);
    break;

    default:
      break;
  }

  return state;

}// Fim do reducers

export default authReducer 