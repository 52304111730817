import React from "react";
import styled from "styled-components";

import { Icon } from "semantic-ui-react";
import { INOVA_MIND_COLOR } from "../../../UI/Assets";
import Validators from "./../../../../services/Validators";


// Lista os documentos.
const ListarDocumentosComponent = (props) => {
  let iconeName = "";
  let mascara = null;

  if(props.tipoDocumento === "cpf") {
    iconeName = "user";
    mascara = Validators.mascaraCpf;
  }
  else if(props.tipoDocumento === "cnpj") {
    iconeName = "suitcase";
    mascara = Validators.mascaraCnpj;
  }
  else {
    iconeName = "building";
    mascara = Validators.mascaraCcm;
  }

  return(
    <ListarDocumentosWrapper>
      <ul>
      {
        props.documentos.map(doc => {
          return(
            <li key={doc.numero} style={{listStyleType: "none"}} >
              <div style={{color: props.documentoSelecionado.numero === doc.numero ? INOVA_MIND_COLOR : "black",
              cursor: "pointer"}} onClick={() => props.selecionarDocumento({...doc, tipo: props.tipoDocumento})}>
                <Icon name={iconeName} />
                <span className="lista-documento-numero">
                  {mascara(doc.numero)}
                </span>
              </div>
              {/* <Icon name="trash" color="red" style={{marginRight: "0.4rem", cursor: "pointer"}} /> */}
              <Icon name={doc.processed ? "check circle outline" : "wait"} 
                color={doc.processed ? "green" : "orange"} />
            </li>
          )
        })
      }
    </ul>
    </ListarDocumentosWrapper>
  );
}

export default ListarDocumentosComponent;

const ListarDocumentosWrapper = styled.div`
  & ul {
    margin-top: 0.5rem;
    padding-inline-start: 1rem;
  }

  & > ul > li {
    margin: 1.3rem 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > ul > li > div:hover {
    & .lista-documento-numero {
      color: ${INOVA_MIND_COLOR};
    }
  }

  & > ul > li > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: start;
  }
`; 