import 'rxjs-compat';
import { combineEpics } from 'redux-observable'
import { authEpics } from "./auth/auth.epics";
import { leadsEpics, selecionaLeadsEpics, adicionaLeadsEpics, 
  deletarLeadsEpics, deletarDocumentosEpics, 
  adicionarDocumentosEpics, editaLeadEpics, arquivarLeadEpics } from "./leads/leads.epics";

const epics = [
  authEpics,
  leadsEpics,
  selecionaLeadsEpics,
  adicionaLeadsEpics,
  deletarLeadsEpics,
  deletarDocumentosEpics,
  adicionarDocumentosEpics,
  editaLeadEpics,
  arquivarLeadEpics
]

const epicsRoots = combineEpics(...epics);

export default epicsRoots;
