import styled from "styled-components";
import { BORDA_COR, COR_FONTE_TITULO } from "./../../../UI/Assets";

export const InfoDadosLeadsWrapper = styled.div`
  flex: 3;
  border: solid ${BORDA_COR};
  border-width: 0 1px 0 0;
  padding: 0.5rem 0 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & .documento-dados-nome {
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .documento-dados-nome span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: ${COR_FONTE_TITULO};
  }
`;

export const SelecionaDocumentoWrapper = styled.div`
  flex: 1;
  border: solid ${BORDA_COR};
  border-width: 0 1px 0 0;

  padding: 0.5rem 0 0 0;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: stretch;

  & span {
    display: inline-flex;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: ${COR_FONTE_TITULO};
  }
`;

export const DocumentoInfoTituloWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & .numero-documento {
    display: inline-flex;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
    font-variant: small-caps;
    text-decoration: underline;
    color: ${COR_FONTE_TITULO};
  }
`;

export const DocumentoDadosWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: 0;

  

  & .documento-dados-lista {
    width: 88%;
    max-width: 1000px;
    padding: 0 0.5rem;
    overflow-y: auto;
  }
`;
